import { Image, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Vertical } from "../mantine-layout-components/Vertical";
import { InspoCardTitle } from "../ui/InspoCardContent";

type TitleWithBgColorContentProps = {
  title: string;
};

export const titleWithBgColorContent = {
  fashion: {
    title: "Chroma Couture",
  },
  editorial: {
    title: "Dispatch Daily",
  },
  finance: {
    title: "Capital Crush",
  },
  food: {
    title: "Savor Spire",
  },
  sport: {
    title: "Velocity Vibe",
  },
  beauty: {
    title: "Glamour Glow",
  },
} as const satisfies Record<string, TitleWithBgColorContentProps>;

export default function TitleWithBgColor({
  colorScheme,
  content,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<TitleWithBgColorContentProps> &
  BentoCardChildrenProps & {
    uploadedImagePath?: string;
    customTexts?: Record<string, string>;
  }) {
  if (!content) return null;

  return (
    <Vertical fullW fullH center bg={colorScheme.background} {...props}>
      {uploadedImagePath ? (
        <Image
          src={uploadedImagePath}
          alt="Uploaded image"
          radius={"xxs"}
          h={"70%"}
          maw={"90%"}
          fit="contain"
        />
      ) : (
        <InspoCardTitle
          variant="card-headline-sm"
          c={colorScheme.textdark}
          textId="title"
          {...props}
        >
          {content.title}
        </InspoCardTitle>
      )}
    </Vertical>
  );
}
