import { twMerge } from "tailwind-merge";
import { clsx, type ClassValue } from "clsx";
import html2canvas from "html2canvas";

export function getForDevOrProd(dev, prod) {
  return {
    development: dev,
    production: prod,
  }[import.meta.env.MODE];
}

export const host = getForDevOrProd(
  `http://localhost:4321`,
  `https://www.inspotype.com`,
);

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function getPatchedCanvasForHtml2Canvas({
  width,
  height,
  scale = 1,
}: {
  width: number;
  height: number;
  scale: number;
}) {
  const root = document.body;

  // we need the scale to manually resize the canvas as well as passing it to html2canvas
  // const scale = window.devicePixelRatio;

  const canvas = document.createElement("canvas");
  canvas.width = width * scale;
  canvas.height = height * scale;

  const ctx = canvas.getContext("2d");
  if (!ctx) return;
  const originalDrawImage = ctx.drawImage;

  // @ts-ignore
  ctx.drawImage = function (image, sx, sy, sw, sh, dx, dy, dw, dh) {
    if (image instanceof HTMLImageElement) {
      if (sw / dw < sh / dh) {
        const _dh = dh;
        dh = sh * (dw / sw);
        dy = dy + (_dh - dh) / 2;
      } else {
        const _dw = dw;
        dw = sw * (dh / sh);
        dx = dx + (_dw - dw) / 2;
      }
    }

    return originalDrawImage.call(ctx, image, sx, sy, sw, sh, dx, dy, dw, dh);
  };

  return { canvas, scale };
}
