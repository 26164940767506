import { Box, Text } from "@mantine/core";
import { useClickOutside, useHover } from "@mantine/hooks";
import {
  IconChevronLeft,
  IconChevronRight,
  IconCircleArrowRight,
  IconLock,
} from "@tabler/icons-react";
import { useState } from "react";
import { Horizontal } from "./mantine-layout-components/Horizontal";

import { useFont, useFonts } from "../contexts/FontsContext";
import { useUser } from "../contexts/UserContext";
import { LifetimeModalTrigger, track } from "../utils";
import style from "./../../../styles/CustomComponents.module.scss";
import { FontPicker } from "./FontPicker";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";
import { openModalLoginIfNeeded } from "./ModalLogin";
import { InspoActionIcon } from "./ui/InspoActionIcon";
import { InspoBadge } from "./ui/InspoBadge";
import { InspoKeyboardShortcutTooltip } from "./ui/InspoKeyboardShortcutTooltip";
import { InspoToolbarActionIcon } from "./ui/InspoToolbarActionIcon";
import { InspoToolbarDivider } from "./ui/InspoToolbarDivider";

export const FontPairingSection = ({}: {}) => {
  const { handleFontPairChange } = useFonts();

  return (
    <Horizontal
      classNames={{ root: style.bottomBarSectionRoot }}
      gap={12}
      noWrap
    >
      <Text variant="label-lg">PAIRING</Text>

      <Horizontal gap={0} h={44} center noWrap>
        <Horizontal gap={"xxxs"} centerH noWrap>
          <FontSection type="heading" />
          <Text variant="paragraph-md">+</Text>
          <FontSection type="body" />
        </Horizontal>

        <InspoToolbarDivider />

        <InspoKeyboardShortcutTooltip
          label={"Previous/Next font pairing"}
          shortcut={["←", "→"]}
        >
          <Horizontal
            gap={"xxxs"}
            h={52}
            center
            noWrap
            mr={-4}
            style={{
              borderTopRightRadius: "calc(var(--mantine-radius-xs) + 2px)",
              borderBottomRightRadius: "calc(var(--mantine-radius-xs) + 2px)",
              overflow: "hidden",
            }}
          >
            <InspoActionIcon
              theme="dark"
              icon={<IconChevronLeft />}
              h={"100%"}
              w={30}
              radius={0}
              size={"default"}
              onClick={() => handleFontPairChange("back")}
            />
            <InspoActionIcon
              theme="dark"
              icon={<IconChevronRight />}
              size={20}
              h={"100%"}
              w={30}
              radius={0}
              onClick={() => handleFontPairChange("next")}
            />
          </Horizontal>
        </InspoKeyboardShortcutTooltip>
      </Horizontal>
    </Horizontal>
  );
};

function FontSection({ type }: { type: "heading" | "body" }) {
  const { user, isPro } = useUser();
  const { font, isLockedAtFont, toggleLockFont } = useFont(type);

  const refClickOutside = useClickOutside(() => setShowPicker(false));
  const { hovered: hovered, ref: refHover } = useHover();
  const [showPicker, setShowPicker] = useState(false);
  const shouldShowLock = hovered || isLockedAtFont !== undefined;

  return (
    <Box ref={refClickOutside}>
      {showPicker && <FontPicker type={type} setShow={setShowPicker} />}

      <Horizontal
        ref={refHover}
        h={44}
        w={"100%"}
        miw={"220px"}
        px={"xxs"}
        spaceBetween
        center
        noWrap
        style={{
          outline: hovered ? "2px solid #000" : undefined,
          cursor: "pointer",
          gap: "10px",
          padding: "10.5px 0px",
          borderRadius: "var(--mantine-radius-xs)",
          backgroundColor: "white",
        }}
        onClick={() => {
          if (
            openModalLoginIfNeeded({
              user,
              isMobile: false,
              source: "toggle_font_picker",
            })
          )
            return;
          track({
            event: "toggle_font_picker",
            properties: { type: type },
          });
          setShowPicker(!showPicker);
        }}
      >
        <InspoKeyboardShortcutTooltip label={"Lock font"} shortcut={undefined}>
          <InspoToolbarActionIcon
            show={shouldShowLock}
            icon={<IconLock />}
            onClick={() => {
              if (
                openModalLoginIfNeeded({
                  user,
                  isMobile: false,
                  source: "lock_font",
                })
              ) {
                return;
              }

              if (
                openModalLifetimeIfNeeded({
                  user,
                  isPro,
                  source:
                    type === "heading"
                      ? LifetimeModalTrigger.FONT_LOCK_HEADING
                      : LifetimeModalTrigger.FONT_LOCK_BODY,
                })
              ) {
                return;
              }

              toggleLockFont();

              track({
                event: "toggle_font_lock",
                properties: { type: type },
              });
            }}
          />
        </InspoKeyboardShortcutTooltip>

        {(() => {
          const badge = {
            free: { theme: "lime", label: "FREE" },
            adobe: { theme: "sky", label: "ADOBE" },
            custom: { theme: "dark", label: "CUSTOM" },
          }[font.source as keyof typeof badge];

          return (
            badge && (
              <InspoBadge
                theme={badge.theme as "lime" | "sky" | "dark"}
                mt={"-20px"}
                py={undefined}
                className="absolute top-[22px] z-10"
              >
                {badge.label}
              </InspoBadge>
            )
          );
        })()}

        <Text
          variant="paragraph-md"
          mt={"xs"}
          h={"100%"}
          mx={"auto"}
          ta={"center"}
          w={"150px"}
          style={{
            flexGrow: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {/* @ts-ignore */}
          {font["readable-font-family"]}
        </Text>

        <InspoKeyboardShortcutTooltip
          label={"Go to font website"}
          shortcut={undefined}
        >
          <InspoToolbarActionIcon
            show={hovered && !!font["foundry-url"]}
            icon={
              <IconCircleArrowRight style={{ transform: "rotate(-45deg)" }} />
            }
            onClick={() => {
              track({
                event: "click_foundry_link",
                properties: {
                  type: type,
                  font: font["readable-font-family"],
                  url: font["foundry-url"],
                },
              });
              window.open(font["foundry-url"], "_blank");
            }}
          />
        </InspoKeyboardShortcutTooltip>
      </Horizontal>
    </Box>
  );
}
