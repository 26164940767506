import { IconMessageCircle, IconRefresh, IconRotate } from "@tabler/icons-react";

export function ErrorBoundarySection({error}: {error: any}) {
  const urlParams = new URLSearchParams(window.location.search);
  const userEmail = urlParams.get('email') || '[insert email]';
  const userName = urlParams.get('name') || '[insert name]';

  const handleReloadInspotype = () => {
    window.location.reload();
  };

  const handleResetInspotype = () => {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    window.location.reload();
  };

  const handleContactSupport = () => {
    const userInfo = `
User Information:
- Name: ${userName}
- Email: ${userEmail}

Technical Details:
- URL: ${window.location.href}
- Browser: ${navigator.userAgent}
- Date/Time: ${new Date().toISOString()}
- Local Storage Keys: ${Object.keys(localStorage).join(', ')}

Error Details:
${error.message}

Stack Trace:
${error.stack || "No stack trace available"}`;

    window.location.href = `mailto:hello@inspotype.com?subject=${encodeURIComponent("Inspotype Error Report")}&body=${encodeURIComponent(userInfo)}`;
  };

  interface ActionButtonProps {
    onClick: () => void;
    icon: React.ReactNode;
    children: React.ReactNode;
  }

  const ActionButton = ({ onClick, icon, children }: ActionButtonProps) => (
    <button
      onClick={onClick}
      className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
    >
      {icon}
      {children}
    </button>
  );

  interface ActionSectionProps {
    number: number;
    description: string;
    button: {
      onClick: () => void;
      icon: React.ReactNode;
      text: string;
    };
    note?: string;
  }

  const ActionSection = ({ number, description, button, note }: ActionSectionProps) => (
    <div>
      <p className="text-gray-700 mb-2">
        {number}. {description}
        {note && <span className="text-gray-500 text-sm"> ({note})</span>}
      </p>
      <ActionButton onClick={button.onClick} icon={button.icon}>
        {button.text}
      </ActionButton>
    </div>
  );

  const actions: ActionSectionProps[] = [
    {
      number: 1,
      description: "First let's try to reload Inspotype:",
      button: {
        onClick: handleReloadInspotype,
        icon: <IconRefresh className="mr-2 h-4 w-4" />,
        text: "Reload Inspotype"
      }
    },
    {
      number: 2,
      description: "Still broken? Resetting Inspotype might help",
      note: "you'll have to log in again",
      button: {
        onClick: handleResetInspotype,
        icon: <IconRotate className="mr-2 h-4 w-4" />,
        text: "Reset Inspotype"
      }
    },
    {
      number: 3,
      description: "If you're still having trouble, contact us:",
      button: {
        onClick: handleContactSupport,
        icon: <IconMessageCircle className="mr-2 h-4 w-4" />,
        text: "Contact support"
      }
    }
  ];

  return (
    <div className="min-h-screen bg-white p-8">
      <div className="max-w-2xl mx-auto space-y-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Oops, an unexpected error occurred!
        </h1>

        <div className="space-y-6">
          {actions.map((action, index) => (
            <ActionSection key={index} {...action} />
          ))}
        </div>

        <div className="mt-8">
          <h2 className="text-sm font-medium text-gray-900 mb-2">Error details</h2>
          <pre className="bg-gray-50 p-4 rounded-md overflow-auto text-sm font-mono text-gray-700">
            {error.message}
            {error.stack && `\n\n${error.stack}`}
          </pre>
        </div>
      </div>
    </div>
  );
}