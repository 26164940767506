import { AspectRatio, Image, Space, Text, Title } from "@mantine/core";
import type { InspoTypeCardProps } from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { InspoCardText, InspoCardTitle } from "../ui/InspoCardContent";

type EcommItemContentProps = {
  title: string;
  subtitle: string;
  subtitle2?: string;
  bottomSubtitle?: string;
  imgs: string[];
  alt: string;
  align: "center" | "bottomLeft";
};

export const ecommItemContent = {
  fashion: {
    title: "Crazy Flight MVP Men's Tank",
    subtitle: "T-shirt",
    subtitle2: "2 Colors",
    bottomSubtitle: "$59",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_square",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },
  food: {
    title: "Organic Acai Bowl",
    subtitle: "Breakfast",
    subtitle2: "Vegan",
    bottomSubtitle: "$12.99",
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_square",
    }),
    alt: "Food Image",
    align: "bottomLeft",
  },
  beauty: {
    title: "Hydrating Face Serum",
    subtitle: "Skincare",
    subtitle2: "30ml",
    bottomSubtitle: "$45",
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_square",
    }),
    alt: "Beauty Product Image",
    align: "bottomLeft",
  },
  editorial: {
    title: "The Rise of Digital Banking",
    subtitle: "Financial Technology",
    subtitle2: "5 Minute Read",
    bottomSubtitle: "Read Article",
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_square",
    }),
    alt: "Editorial Image",
    align: "bottomLeft",
  },
  finance: {
    title: "Premium Credit Card",
    subtitle: "Rewards Card",
    subtitle2: "2% Cashback",
    bottomSubtitle: "Apply Now",
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_square",
    }),
    alt: "Finance Product Image",
    align: "bottomLeft",
  },
  sport: {
    title: "Pro Tennis Racket",
    subtitle: "Equipment",
    subtitle2: "3 Colors",
    bottomSubtitle: "$199",
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_square",
    }),
    alt: "Sport Equipment Image",
    align: "bottomLeft",
  },
} as const satisfies Record<string, EcommItemContentProps>;

export default function EcommItem({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<EcommItemContentProps> & BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Vertical fullW fullH center bg={colorScheme.background} px="md" {...props}>
      <Horizontal noWrap fullW>
        <AspectRatio ratio={1 / 1} mah={200} maw={200}>
          <Image
            src={uploadedImagePath ?? content.imgs[imgIndex]}
            alt={content.alt}
            radius={"xxs"}
            fit="cover"
          />
        </AspectRatio>

        <Vertical gap={0}>
          <InspoCardTitle
            variant="card-headline-xs"
            c={colorScheme.textdark}
            textId="title"
            {...props}
          >
            {content.title}
          </InspoCardTitle>

          <Space h={"xs"} />

          <InspoCardText
            variant="card-paragraph-lg"
            c={colorScheme.textdark}
            textId="subtitle"
            {...props}
          >
            {content.subtitle}
          </InspoCardText>

          <Space h={"xxs"} />

          <InspoCardText
            variant="card-label-lg"
            c={colorScheme.textdark}
            textId="subtitle2"
            {...props}
          >
            {content.subtitle2}
          </InspoCardText>

          <Space h={"md"} />

          <InspoCardText
            variant="card-paragraph-lg"
            c={colorScheme.textdark}
            textId="bottomSubtitle"
            {...props}
          >
            {content.bottomSubtitle}
          </InspoCardText>
        </Vertical>
      </Horizontal>
    </Vertical>
  );
}
