import {
  Box,
  Text,
  Textarea,
  TextInput,
  Title,
  type BoxProps,
  type TextProps,
  type TitleProps,
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { InspoEvent, LifetimeModalTrigger } from "../../utils";
import { openModalLifetimeIfNeeded } from "../ModalLifetime";
import { openModalLoginIfNeeded } from "../ModalLogin";

interface InspoCardContentProps<T> {
  textId: string;
  variant?: T extends TitleProps | TextProps ? T["variant"] : never;
  c?: T extends TitleProps | TextProps ? T["c"] : never;
  style?: T extends TitleProps | TextProps ? T["style"] : never;
  children?: React.ReactNode;
}

export function InspoCardTitle({
  textId,
  variant,
  c,
  style,
  children,
  ...titleProps
}: Omit<InspoCardContentProps<TitleProps>, "onchange">) {
  return (
    <InspoCardContent
      {...titleProps}
      textId={textId}
      variant={variant}
      c={c}
      style={style}
      component={Title}
    >
      {children}
    </InspoCardContent>
  );
}

export function InspoCardText({
  textId,
  variant,
  c,
  style,
  children,
  ...textProps
}: Omit<InspoCardContentProps<TextProps & BoxProps>, "onchange">) {
  return (
    <InspoCardContent
      {...textProps}
      textId={textId}
      variant={variant}
      c={c}
      style={style}
      component={Text}
    >
      {children}
    </InspoCardContent>
  );
}

interface InnerInspoCardContentProps
  extends Omit<TitleProps | TextProps, "onChange"> {
  textId: string;
  variant?: TitleProps["variant"];
  c?: TitleProps["c"];
  style?: TitleProps["style"];
  component: React.ComponentType<any>;
  children?: React.ReactNode;
}

export function InspoCardContent({
  textId,
  variant = "card-headline-sm",
  c,
  style,
  component: Component,
  children,
  ...props
}: InnerInspoCardContentProps) {
  const { user, isPro } = useUser();
  const editable = true;
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState<string | undefined>(undefined);

  const value = props["cardState"]?.["customTexts"]?.[textId] ?? children ?? "";

  useEffect(() => {
    function onResetCustomTexts() {
      setEditedValue(undefined);
      setIsEditing(false);
    }

    document.addEventListener(InspoEvent.RESET_CUSTOM_TEXT, onResetCustomTexts);

    return () => {
      document.removeEventListener(
        InspoEvent.RESET_CUSTOM_TEXT,
        onResetCustomTexts,
      );
    };
  }, [value]);

  const handleDoubleClick = () => {
    if (
      openModalLoginIfNeeded({
        user: user,
        isMobile: false,
        source: "custom_text",
      })
    ) {
      return;
    }

    if (editable) {
      setEditedValue(value);
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (editedValue !== value) {
      if (
        openModalLifetimeIfNeeded({
          user: user,
          isPro,
          source: LifetimeModalTrigger.CUSTOM_TEXTS,
        })
      ) {
        setEditedValue(value);
        return;
      }

      props["setCardState"]?.({
        // @ts-ignore
        customTexts: {
          ...(props["cardState"]?.["customTexts"] || {}),
          [textId]: editedValue ?? "",
        },
      });
      document.dispatchEvent(
        new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT),
      );
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleBlur();
    }
    if (e.key === "Escape") {
      setEditedValue(value);
      setIsEditing(false);
    }
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.selectionStart = textarea.value.length;
      textarea.selectionEnd = textarea.value.length;
      textarea.scrollTop = textarea.scrollHeight;
    }
  }, [isEditing]);

  if (isEditing) {
    return (
      <Textarea
        ref={textareaRef}
        value={editedValue}
        onChange={(e) => setEditedValue(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        variant="unstyled"
        bg={`${c}20`}
        px={"xs"}
        py={"xxs"}
        ta={props.ta}
        multiline={true}
        w={"100%"}
        styles={{
          root: {
            borderRadius: "var(--mantine-radius-xxs)",
          },
          input: {
            // @ts-ignore
            color: c,
            fontSize: "12px",
          },
        }}
        autoFocus
      />
    );
  }

  return (
    <Box
      pos="relative"
      // onDoubleClick={handleDoubleClick}
      onClick={handleDoubleClick}
      {...props}
      style={{ ...style }}
    >
      <Box
        pos="absolute"
        top={-4}
        left={-4}
        right={-4}
        bottom={-4}
        style={{
          zIndex: 1,
          "--editable-text-bg": c ? `${c}20` : undefined,
        }}
        className={`${
          editable
            ? `cursor-pointer rounded-md transition-all duration-100 px-4 py-2 hover:bg-[--editable-text-bg]`
            : ""
        }`}
      />
      <Component
        {...props}
        variant={variant}
        c={c}
        style={{
          zIndex: 2,
        }}
      >
        {editedValue ?? value}
      </Component>
    </Box>
  );
}
