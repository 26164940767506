import { Box, Text, Title } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import {
  customizeTextVariantIfNeededForCardPreview,
  type CardPreviewCustomizationProps,
  type InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import { InspoCardText, InspoCardTitle } from "../ui/InspoCardContent";

type TagsGridContentProps = {
  title: string;
  tags: { tag: string; selected: boolean }[];
};

export const tagsGridContent = {
  editorial: {
    title: "Now, pick\nyour interests",
    tags: [
      { tag: "Nature", selected: false },
      { tag: "Energy", selected: true },
      { tag: "MeToo", selected: true },
      { tag: "Community", selected: false },
      { tag: "Cooking", selected: true },
      { tag: "Feminism", selected: false },
      { tag: "Youth", selected: false },
      { tag: "Conversation", selected: true },
      { tag: "Art", selected: false },
      { tag: "Music", selected: false },
    ],
  },
  fashion: {
    title: "Explore\nFashion Topics",
    tags: [
      { tag: "Sustainable", selected: true },
      { tag: "Vintage", selected: false },
      { tag: "Streetwear", selected: true },
      { tag: "Luxury", selected: false },
      { tag: "Minimalism", selected: true },
      { tag: "Accessories", selected: false },
      { tag: "Trends", selected: true },
      { tag: "Designers", selected: false },
      { tag: "Ethical", selected: true },
      { tag: "Craftsmanship", selected: false },
    ],
  },
  sport: {
    title: "Choose Your\nSport Focus",
    tags: [
      { tag: "Training", selected: true },
      { tag: "Nutrition", selected: false },
      { tag: "Endurance", selected: true },
      { tag: "Wellness", selected: false },
      { tag: "Equipment", selected: true },
      { tag: "Recovery", selected: false },
      { tag: "Coaching", selected: true },
      { tag: "Performance", selected: false },
      { tag: "Teams", selected: true },
      { tag: "Outdoors", selected: false },
    ],
  },
  beauty: {
    title: "Discover\nBeauty Topics",
    tags: [
      { tag: "Skincare", selected: true },
      { tag: "Natural", selected: false },
      { tag: "Wellness", selected: true },
      { tag: "Ingredients", selected: false },
      { tag: "Routines", selected: true },
      { tag: "Clean", selected: false },
      { tag: "Organic", selected: true },
      { tag: "Science", selected: false },
      { tag: "Aging", selected: true },
      { tag: "Treatments", selected: false },
    ],
  },
  food: {
    title: "Select Your\nCulinary Interests",
    tags: [
      { tag: "Seasonal", selected: true },
      { tag: "Organic", selected: false },
      { tag: "Techniques", selected: true },
      { tag: "Traditions", selected: false },
      { tag: "Ingredients", selected: true },
      { tag: "Wellness", selected: false },
      { tag: "Culture", selected: true },
      { tag: "Innovation", selected: false },
      { tag: "Sustainable", selected: true },
      { tag: "Local", selected: false },
    ],
  },
  finance: {
    title: "Browse\nFinancial Topics",
    tags: [
      { tag: "Markets", selected: true },
      { tag: "Investment", selected: false },
      { tag: "Planning", selected: true },
      { tag: "Strategy", selected: false },
      { tag: "Business", selected: true },
      { tag: "Global", selected: false },
      { tag: "Economy", selected: true },
      { tag: "Analysis", selected: false },
      { tag: "Trends", selected: true },
      { tag: "Research", selected: false },
    ],
  },
} as const satisfies Record<string, TagsGridContentProps>;

export default function TagsGrid({
  colorScheme,
  content,
  titleFontFamily,
  bodyFontFamily,
  ...props
}: InspoTypeCardProps<TagsGridContentProps> &
  BentoCardChildrenProps &
  CardPreviewCustomizationProps) {
  if (!content) return null;

  const [nodeKey, setNodeKey] = useState(0);

  useEffect(() => {
    if (!document) return;
    const windowWidth = document.documentElement.clientWidth;

    const node = document?.getElementById("zoomTags");
    if (!node) {
      setNodeKey((prev) => prev + 1);
      return;
    }

    // @ts-ignore
    const initialValue = node.style.zoom;

    // make this scale up and down depending on windows width
    const zoomNormal = Math.min(100 * (windowWidth / 1000), 100) + "%";
    const zoomCompact = Math.min(70 * (windowWidth / 1200), 100) + "%";

    if (windowWidth < 1200) {
      // @ts-ignore
      node.style.zoom = zoomCompact;
    } else {
      // @ts-ignore
      node.style.zoom = zoomNormal;
    }

    return () => {
      // Restore default value
      // @ts-ignore
      node.style.zoom = initialValue;
    };
  }, [nodeKey]);

  return (
    <Vertical
      fullW
      fullH
      centerH
      p={"md"}
      gap={"sm"}
      bg={colorScheme.background}
      {...props}
    >
      <InspoCardTitle
        variant="card-headline-xs"
        c={colorScheme.textdark}
        ta={"start"}
        textId="title"
        {...props}
      >
        {content.title}
      </InspoCardTitle>

      <Vertical gap={"xxs"} id="zoomTags">
        {content.tags
          .reduce((acc, el, i) => {
            if (i % 2 === 0) {
              acc.push([el]);
            } else {
              acc[acc.length - 1].push(el);
            }
            return acc;
          }, [] as any)
          .map((row: { tag: string; selected: boolean }[], i: number) => (
            <Horizontal key={i} gap={"xxs"} align="flex-start" noWrap>
              {row.map((el, j) => (
                <Horizontal
                  key={j}
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderRadius: "var(--mantine-radius-xxl)",
                    borderColor: colorScheme.ctadark,
                  }}
                  noWrap
                  gap={"xs"}
                  py={"xxs"}
                  px={"xs"}
                  bg={
                    el.selected ? colorScheme.ctadark : colorScheme.background
                  }
                >
                  <InspoCardText
                    variant={customizeTextVariantIfNeededForCardPreview(
                      "card-paragraph-lg",
                      bodyFontFamily,
                    )}
                    ff={bodyFontFamily}
                    c={
                      el.selected ? colorScheme.textlight : colorScheme.textdark
                    }
                    textId={`tag-${i}-${j}`}
                    {...props}
                  >
                    {el.tag}
                  </InspoCardText>
                  <Box
                    lh={0}
                    style={{
                      transform: el.selected ? "rotate(45deg)" : undefined,
                    }}
                  >
                    <IconPlus
                      stroke={2}
                      size={16}
                      color={
                        el.selected
                          ? colorScheme.textlight
                          : colorScheme.textdark
                      }
                    />
                  </Box>
                </Horizontal>
              ))}
            </Horizontal>
          ))}
      </Vertical>
    </Vertical>
  );
}
