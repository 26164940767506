import { Avatar, Image, Menu, Text, Title } from "@mantine/core";
import { IconChevronDown, IconSparkles } from "@tabler/icons-react";
import { LifetimeModalTrigger, track } from "../utils";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { InspoBadge } from "./ui/InspoBadge";
import { InspoButtonUnderline } from "./ui/InspoButtonUnderline";
import { InspoButton } from "./ui/InspoButton";
import { useUser } from "../contexts/UserContext";
import { useIsMobile } from "../hooks/useIsMobile";
import { openModalUpdates } from "./ModalUpdates";
import { openModalSaved } from "./savedModal/ModalSaved";

import { openModalLifetimeIfNeeded } from "./ModalLifetime";
export const AppHeader = ({}: {}) => {
  const isMobile = useIsMobile();
  const { user, isPro } = useUser();

  return (
    <Horizontal
      fullW
      h={isMobile ? 52 : 72}
      center={isMobile}
      noWrap
      style={{ justifyContent: "space-between" }}
    >
      {isMobile && (
        <Horizontal gap={"xs"} noWrap>
          <Title variant="headline-xs" fz={24} c={"white"}>
            inspotype
          </Title>
          <InspoBadge theme="light">MINI</InspoBadge>
        </Horizontal>
      )}
      {!isMobile && (
        <Horizontal gap={"sm"} noWrap>
          <Title variant="headline-sm" fz={24} style={{cursor: "default"}}>
            inspotype
          </Title>

          <InspoButtonUnderline
            style={{marginTop: "var(--mantine-spacing-xxs)"}}  
            onClick={() => {
              track({
                event: "open_newsletter_modal",
                properties: { source: "header" },
              });

              openModalUpdates({ isMobile });
            }}
          >
            Newsletter
          </InspoButtonUnderline>

          <InspoButtonUnderline
            style={{marginTop: "var(--mantine-spacing-xxs)"}}  
            onClick={() => {
              track({
                event: "navigate_to_pairings",
                properties: { source: "header" },
              });
              window.open("/pairings", "_blank");
            }}
          >
            Pairings
          </InspoButtonUnderline>
        </Horizontal>
      )}

      <Horizontal gap={"lg"} noWrap>
        {!isPro && (
          <InspoButton
            theme="accent"
            iconPos="right"
            icon={<IconSparkles />}
            onClick={() => {
              openModalLifetimeIfNeeded({
                user,
                isPro,
                source: LifetimeModalTrigger.CTA,
              });
            }}
          >
            Upgrade to unlock everything
          </InspoButton>
        )}

        <Menu shadow="md" width={200} position="bottom-end">
          <Menu.Target>
            <Horizontal gap={"xs"} style={{ cursor: "pointer" }} noWrap>
              {user && (
                <Avatar size={24}>
                  <Image
                    referrerPolicy="no-referrer"
                    src={user.profile_image_url}
                    alt={user.full_name}
                  />
                </Avatar>
              )}
              {!isMobile && (
                <Text variant="paragraph-lg">
                  {user ? user.full_name : "Login"}
                </Text>
              )}
              {!!user && isPro === true && (
                <InspoBadge theme="purple">PRO</InspoBadge>
              )}
              {user && (
                <IconChevronDown size={20} color={isMobile ? "white" : ""} />
              )}
            </Horizontal>
          </Menu.Target>
          <Menu.Dropdown>
            {!user && (
              <form
                action={`/api/auth/signin?signinOrigin=${isMobile ? "mobile" : "desktop"}`}
                method="POST"
              >
                <Menu.Item component="button" type="submit">
                  Login with Google
                </Menu.Item>
              </form>
            )}

            {user && (
              <>
                <Menu.Label>
                  <Text variant="label-md" opacity={0.6} mt={"xs"}>
                    FAVORITES
                  </Text>
                </Menu.Label>
                <Menu.Item
                  onClick={() =>
                    openModalSaved({
                      step: undefined,
                      shareUrl: undefined,
                    })
                  }
                >
                  Saved
                </Menu.Item>

                <Menu.Label>
                  <Horizontal gap={"xs"} fullW spaceBetween centerH mt={"xs"}>
                    <Text opacity={0.6} variant="label-md">
                      BILLING
                    </Text>

                    {user?.status && (
                      <InspoBadge theme="dark">{user.status}</InspoBadge>
                    )}
                  </Horizontal>
                </Menu.Label>
                {user?.customerPortalUrl && (
                  <Menu.Item
                    component="a"
                    href={user.customerPortalUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Customer Portal
                  </Menu.Item>
                )}
                <Menu.Item
                  mt={"xs"}
                  onClick={() => {
                    track({
                      event: "signout",
                      properties: {},
                    });
                    // @ts-ignore
                    window.posthog?.reset();
                    window.location.href = "/api/auth/signout";
                  }}
                >
                  Logout
                </Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      </Horizontal>
    </Horizontal>
  );
};
