import {
  bodyALTRiviera,
  bodyALTSystema,
  bodyAcuminProWide,
  bodyAdobeCaslon,
  bodyAeonik,
  bodyAeonikFono,
  bodyAktivGrotesk,
  bodyArchivo,
  bodyArticulat,
  bodyAtlasGrotesk,
  bodyBallingerMono,
  bodyBarlow,
  bodyBaskerville,
  bodyBastardoGrotesk,
  bodyBeVietnamPro,
  bodyBespoke,
  bodyBitter,
  bodyBlanchard,
  bodyBookmania,
  bodyBroadsheet,
  bodyBwModelica,
  bodyBwNistaGrot,
  bodyBwSeidoRaw,
  bodyCabinetGrotesk,
  bodyCanela,
  bodyClashGrotesk,
  bodyConforto,
  bodyDMSans,
  bodyDelegate,
  bodyElza,
  bodyEpilogue,
  bodyFabioXM,
  bodyFaro,
  bodyFavorit,
  bodyFreight,
  bodyFutura,
  bodyGeist,
  bodyGelica,
  bodyGeneralSans,
  bodyGillSansNova,
  bodyHalyard,
  bodyHankenGrotesk,
  bodyHighgate,
  bodyIBM,
  bodyITCFranklinGothic,
  bodyInconsolata,
  bodyInstrumentSans,
  bodyInter,
  bodyInterstate,
  bodyJafFacit,
  bodyLibreFranklin,
  bodyLinearSans,
  bodyLora,
  bodyMaiola,
  bodyManrope,
  bodyMessinaSans,
  bodyModerat,
  bodyMontserrat,
  bodyMonumentGrotesk,
  bodyMoulin,
  bodyMrEavesSans,
  bodyNeuSans,
  bodyNeueHaasGrotesk,
  bodyNeueHaasUnica,
  bodyNimbusSans,
  bodyNineElms,
  bodyNohemi,
  bodyOnest,
  bodyOpenSans,
  bodyOrbikular,
  bodyOverusedGrotesk,
  bodyPPFragmentSans,
  bodyPPFragmentSerif,
  bodyPPNeueMontreal,
  bodyPerenelleSans,
  bodyPlantin,
  bodyPlayfairDisplay,
  bodyPlusJakartaSans,
  bodyPolySansSlim,
  bodyPoppins,
  bodyProximaNova,
  bodyPublicSans,
  bodyRadial,
  bodyRecia,
  bodyRedHatText,
  bodyRethinkSans,
  bodyRobotoSerif,
  bodyRocGrotesk,
  bodyRom,
  bodySatori,
  bodySatoshi,
  bodySneak,
  bodySocial,
  bodySpaceGrotesk,
  bodyStaffGrotesk,
  bodySupreme,
  bodyTiroTamil,
  bodyTradeGothicNext,
  bodyUfficioSans,
  bodyUncutSans,
  bodyVinilaCond,
  bodyWhyte,
  bodyWorkSans,
  bodyWorkhorseGeoMono,
  bodyWorkhorseGeometric,
} from "./bodyFontConfig";
import {
  TitleCalSans,
  TitleVinilaComp,
  titleABCGravityExt,
  titleALTKlarinet,
  titleALTMariaClara,
  titleALTRigatoni,
  titleAdobeGaramond,
  titleAeonik,
  titleAeonikFono,
  titleAktivGrotesk,
  titleAktivGroteskExt,
  titleAnton,
  titleArizonaFlare,
  titleArizonaSerif,
  titleArticulat,
  titleBaiJamjuree,
  titleBarlowCond,
  titleBaskerville,
  titleBebasNeue,
  titleBookmania,
  titleBricolageGrotesque,
  titleBricolageGrotesqueCond,
  titleBwDarius,
  titleBwFusionaItalic,
  titleBwGradual,
  titleCabinetGrotesk,
  titleCanela,
  titleClashDisplay,
  titleClashGrotesk,
  titleCormorant,
  titleDMSans,
  titleDesirePro,
  titleEBGaramond,
  titleEczar,
  titleElysian,
  titleEpilogue,
  titleErode,
  titleExcon,
  titleFabio,
  titleFaroBold,
  titleFraise,
  titleFranklinGothicURW,
  titleFraunces,
  titleFutura,
  titleGambarino,
  titleGeist,
  titleGelica,
  titleGeneralSans,
  titleGinto,
  titleGosh,
  titleHighgateChisel,
  titleInstrumentSerif,
  titleIvyPresto,
  titleIvyPrestoDisplay,
  titleJubel,
  titleKarla,
  titleKokoro,
  titleLibreBaskerville,
  titleLoosNormal,
  titleLora,
  titleLyon,
  titleMangoGrotesque,
  titleMarcellus,
  titleMargoBeuys,
  titleMarlideDisplay,
  titleMarrSans,
  titleMencken,
  titleMessinaSerif,
  titleMinion,
  titleModerat,
  titleModeratExtended,
  titleMoret,
  titleMoulin,
  titleMullet,
  titleNeueHaasGrotesk,
  titleNeueHaasUnica,
  titleNewKansas,
  titleNewSpiritCondensed,
  titleNoka,
  titleNotoSerif,
  titleOkaySerif,
  titleOnsra,
  titleOswald,
  titlePPAgrandir,
  titlePPCirka,
  titlePPEiko,
  titlePPEikoItalic,
  titlePPFragmentGlare,
  titlePPMori,
  titlePPNeueMontreal,
  titlePPWriter,
  titlePanchang,
  titlePerenelleSerif,
  titlePhudu,
  titlePlaid,
  titlePlantin,
  titlePlayfairDisplay,
  titlePlusJakartaSans,
  titlePoleno,
  titlePrata,
  titleProximaNova,
  titleProximaSera,
  titleRecife,
  titleRedaction20,
  titleRevetra,
  titleRocGrotesk,
  titleRomCompressed,
  titleRubik,
  titleSneak,
  titleSsonder,
  titleStratos,
  titleSuccessor,
  titleSyncopate,
  titleTanker,
  titleTermina,
  titleTerzaReader,
  titleUncutSans,
  titleWhyteInktrap,
  titleWorkSans,
  titleWorkhorseEmpire,
  titleWorkhorseGothic2,
  titleWorkhorseScript,
} from "./titleFontConfig";

export const fontPairs = [
  {
    slug: "newspirit_neuehaas",
    title: titleNewSpiritCondensed,
    body: bodyNeueHaasUnica,
  },
  {
    slug: "rubik_opensans",
    title: titleRubik,
    body: bodyOpenSans,
  },
  {
    slug: "oswald_tirotamil",
    title: titleOswald,
    body: bodyTiroTamil,
  },
  {
    slug: "phudu_onest",
    title: titlePhudu,
    body: bodyOnest,
  },
  {
    slug: "clash_archivo",
    title: titleClashDisplay,
    body: bodyArchivo,
  },
  {
    slug: "erode_supreme",
    title: titleErode,
    body: bodySupreme,
  },
  {
    slug: "marlide_jakarta",
    title: titleMarlideDisplay,
    body: bodyPlusJakartaSans,
  },
  {
    slug: "general_recia",
    title: titleGeneralSans,
    body: bodyRecia,
  },
  {
    slug: "tanker_bespoke",
    title: titleTanker,
    body: bodyBespoke,
  },
  {
    slug: "gambarino_articulat",
    title: titleGambarino,
    body: bodyArticulat,
  },
  {
    slug: "termina_epilogue",
    title: titleTermina,
    body: bodyEpilogue,
  },
  {
    slug: "minion_proximanova",
    title: titleMinion,
    body: bodyProximaNova,
  },
  {
    slug: "franklingothic_tradegothic",
    title: titleFranklinGothicURW,
    body: bodyTradeGothicNext,
  },
  {
    slug: "moret_maiola",
    title: titleMoret,
    body: bodyMaiola,
  },
  {
    slug: "worksans_bitter",
    title: titleWorkSans,
    body: bodyBitter,
  },
  {
    slug: "eczar_librefranklin",
    title: titleEczar,
    body: bodyLibreFranklin,
  },
  {
    slug: "newkansas_satoshi",
    title: titleNewKansas,
    body: bodySatoshi,
  },
  // {
  //   slug: "paralucent_sourceserif",
  //   title: titleParalucent,
  //   body: bodySourceSerif,
  // },
  {
    slug: "marcellus_adobecaslon",
    title: titleMarcellus,
    body: bodyAdobeCaslon,
  },
  {
    slug: "cabinetgrotesk_generalsans",
    title: titleCabinetGrotesk,
    body: bodyGeneralSans,
  },
  {
    slug: "clashdisplay_clashgrotesk",
    title: titleClashDisplay,
    body: bodyClashGrotesk,
  },
  {
    slug: "notoserif_inter",
    title: titleNotoSerif,
    body: bodyInter,
  },
  {
    slug: "lora_jaffacit",
    title: titleLora,
    body: bodyJafFacit,
  },
  {
    slug: "redaction_ibm",
    title: titleRedaction20,
    body: bodyIBM,
  },
  {
    slug: "marlidedisplay_plusjakartasans",
    title: titleMarlideDisplay,
    body: bodyPlusJakartaSans,
  },
  {
    slug: "mangogrotesque_nohemi",
    title: titleMangoGrotesque,
    body: bodyNohemi,
  },
  {
    slug: "plantin_futura",
    title: titlePlantin,
    body: bodyFutura,
  },
  {
    slug: "baskerville_aktivgrotesk",
    title: titleBaskerville,
    body: bodyAktivGrotesk,
  },
  {
    slug: "stratos_manrope",
    title: titleStratos,
    body: bodyManrope,
  },
  {
    slug: "mencken_rocgrotesk",
    title: titleMencken,
    body: bodyRocGrotesk,
  },
  {
    slug: "neuehaas_interstate",
    title: titleNeueHaasUnica,
    body: bodyInterstate,
  },
  {
    slug: "margobeuys_dmsans",
    title: titleMargoBeuys,
    body: bodyDMSans,
  },
  {
    slug: "fabioxm_poppins",
    title: titleFabio,
    body: bodyPoppins,
  },
  {
    slug: "ppfragmentglare_ppfragmentserif",
    title: titlePPFragmentGlare,
    body: bodyPPFragmentSerif,
  },
  {
    slug: "ppwriter_interstate",
    title: titlePPWriter,
    body: bodyInterstate,
  },
  {
    slug: "ppcirka_nimbussans",
    title: titlePPCirka,
    body: bodyNimbusSans,
  },
  {
    slug: "ppeiko_ppneuemontreal",
    title: titlePPEiko,
    body: bodyPPNeueMontreal,
  },
  {
    slug: "ppagrandir_inter",
    title: titlePPAgrandir,
    body: bodyInter,
  },
  {
    slug: "aeonikfono_aeonik",
    title: titleAeonikFono,
    body: bodyAeonik,
  },
  {
    slug: "messinaserif_messinasans",
    title: titleMessinaSerif,
    body: bodyMessinaSans,
  },
  {
    slug: "farobold_faro",
    title: titleFaroBold,
    body: bodyFaro,
  },
  {
    slug: "recife_hankengrotesk",
    title: titleRecife,
    body: bodyHankenGrotesk,
  },
  {
    slug: "fabioxm_plantin",
    title: titleFabio,
    body: bodyPlantin,
  },
  {
    slug: "syncopate_robotoserif",
    title: titleSyncopate,
    body: bodyRobotoSerif,
  },
  {
    slug: "vinilacomp_vinilacond",
    title: TitleVinilaComp,
    body: bodyVinilaCond,
  },
  {
    slug: "calsans_inter",
    title: TitleCalSans,
    body: bodyInter,
  },
  {
    slug: "anton_staffgrotesk",
    title: titleAnton,
    body: bodyStaffGrotesk,
  },
  {
    slug: "whyteInktrap_whyte",
    title: titleWhyteInktrap,
    body: bodyWhyte,
  },

  {
    slug: "arizonaserif_favorit",
    title: titleArizonaSerif,
    body: bodyFavorit,
  },

  {
    slug: "plaid_moderat",
    title: titlePlaid,
    body: bodyModerat,
  },

  {
    slug: "ginto_aeonikfono",
    title: titleGinto,
    body: bodyAeonikFono,
  },

  {
    slug: "recife_social",
    title: titleRecife,
    body: bodySocial,
  },

  {
    slug: "gelica_moderat",
    title: titleGelica,
    body: bodyModerat,
  },

  {
    slug: "moret_poppins",
    title: titleMoret,
    body: bodyPoppins,
  },

  {
    slug: "sneak_sneakmono",
    title: titleSneak,
    body: bodySneak,
  },

  {
    slug: "fraunces_epilogue",
    title: titleFraunces,
    body: bodyEpilogue,
  },

  {
    slug: "epilogue_baskerville",
    title: titleEpilogue,
    body: bodyBaskerville,
  },

  {
    slug: "ivypresto_neuehaasgrotesk",
    title: titleIvyPresto,
    body: bodyNeueHaasGrotesk,
  },

  {
    slug: "prata_redhattext",
    title: titlePrata,
    body: bodyRedHatText,
  },

  {
    slug: "rocgrotesk_elza",
    title: titleRocGrotesk,
    body: bodyElza,
  },

  {
    slug: "bricolagegrotesque_manrope",
    title: titleBricolageGrotesque,
    body: bodyManrope,
  },

  {
    slug: "termina_inter",
    title: titleTermina,
    body: bodyInter,
  },

  {
    slug: "ginto_aenoikfono",
    title: titleGinto,
    body: bodyAeonikFono,
  },

  {
    slug: "clashgrotesk_inter",
    title: titleClashGrotesk,
    body: bodyInter,
  },

  {
    slug: "newkansas_acuminprowide",
    title: titleNewKansas,
    body: bodyAcuminProWide,
  },

  {
    slug: "newkansas_acuminprowide",
    title: titleNewKansas,
    body: bodyAcuminProWide,
  },

  {
    slug: "instrumentserif_instrumentsans",
    title: titleInstrumentSerif,
    body: bodyInstrumentSans,
  },

  {
    slug: "agrandir_inter",
    title: titlePPAgrandir,
    body: bodyInter,
  },

  {
    slug: "articulat_elza",
    title: titleArticulat,
    body: bodyElza,
  },

  {
    slug: "arizonaflare_social",
    title: titleArizonaFlare,
    body: bodySocial,
  },

  {
    slug: "ebgaramond_futura",
    title: titleEBGaramond,
    body: bodyFutura,
  },

  {
    slug: "gambarino_cabinetgrotesk",
    title: titleGambarino,
    body: bodyCabinetGrotesk,
  },

  {
    slug: "gelica_moderat",
    title: titleGelica,
    body: bodyModerat,
  },

  {
    slug: "aktivgroteskext_archivo",
    title: titleAktivGroteskExt,
    body: bodyArchivo,
  },

  {
    slug: "barlowcond_barlow",
    title: titleBarlowCond,
    body: bodyBarlow,
  },

  {
    slug: "baijamjuree_worksans",
    title: titleBaiJamjuree,
    body: bodyWorkSans,
  },

  {
    slug: "neuehaasgrotesk_inter",
    title: titleNeueHaasGrotesk,
    body: bodyInter,
  },

  {
    slug: "loosnormal_monumentgrotesk",
    title: titleLoosNormal,
    body: bodyMonumentGrotesk,
  },

  {
    slug: "romcomp_rom",
    title: titleRomCompressed,
    body: bodyRom,
  },

  {
    slug: "plusjakarta_inter",
    title: titlePlusJakartaSans,
    body: bodyInter,
  },

  {
    slug: "karla_inconsolata",
    title: titleKarla,
    body: bodyInconsolata,
  },

  {
    slug: "newkansas_ballingermono",
    title: titleNewKansas,
    body: bodyBallingerMono,
  },

  {
    slug: "ppeikoitalic_ppneuemontreal",
    title: titlePPEikoItalic,
    body: bodyPPNeueMontreal,
  },

  {
    slug: "stratos_adobecaslon",
    title: titleStratos,
    body: bodyAdobeCaslon,
  },

  {
    slug: "aeonik_aeonik",
    title: titleAeonik,
    body: bodyAeonik,
  },

  // {
  //   slug: "sourceserif_robotoslab",
  //   title: titleSourceSerif,
  //   body: bodyRobotoSlab,
  // },

  {
    slug: "playfairdisplay_bevietnampro",
    title: titlePlayfairDisplay,
    body: bodyBeVietnamPro,
  },

  {
    slug: "ppmori_intero",
    title: titlePPMori,
    body: bodyInter,
  },

  {
    slug: "librebaskerville_publicsans",
    title: titleLibreBaskerville,
    body: bodyPublicSans,
  },

  {
    slug: "ivypresto_halyard",
    title: titleIvyPrestoDisplay,
    body: bodyHalyard,
  },

  {
    slug: "poleno_broadsheet",
    title: titlePoleno,
    body: bodyBroadsheet,
  },

  {
    slug: "panchang_generalsans",
    title: titlePanchang,
    body: bodyGeneralSans,
  },

  {
    slug: "ebgaramond_sneak",
    title: titleEBGaramond,
    body: bodySneak,
  },

  {
    slug: "mangogrotesque_polysansslim",
    title: titleMangoGrotesque,
    body: bodyPolySansSlim,
  },

  {
    slug: "bricolagegrotesquecond_rethinksans",
    title: titleBricolageGrotesqueCond,
    body: bodyRethinkSans,
  },

  {
    slug: "mreaves_bookmania",
    title: titleBookmania,
    body: bodyMrEavesSans,
  },

  {
    slug: "gosh_neusans",
    title: titleGosh,
    body: bodyNeuSans,
  },

  {
    slug: "okayserif_linearsans",
    title: titleOkaySerif,
    body: bodyLinearSans,
  },

  {
    slug: "kokoro_radial",
    title: titleKokoro,
    body: bodyRadial,
  },

  {
    slug: "uncut_uncut",
    title: titleUncutSans,
    body: bodyUncutSans,
  },

  {
    slug: "ivypresto_freight",
    title: titleIvyPrestoDisplay,
    body: bodyFreight,
  },

  {
    slug: "fraise_nineelms",
    title: titleFraise,
    body: bodyNineElms,
  },

  {
    slug: "perenelleserif_perenellesans",
    title: titlePerenelleSerif,
    body: bodyPerenelleSans,
  },

  {
    slug: "highgatechisel_highgate",
    title: titleHighgateChisel,
    body: bodyHighgate,
  },

  {
    slug: "newkansas_inter",
    title: titleNewKansas,
    body: bodyInter,
  },

  {
    slug: "romcomp_monumentgrotesk",
    title: titleRomCompressed,
    body: bodyMonumentGrotesk,
  },

  {
    slug: "noka_opensans",
    title: titleNoka,
    body: bodyOpenSans,
  },

  {
    slug: "uncutsans_inter",
    title: titleUncutSans,
    body: bodyInter,
  },

  {
    slug: "ginto_gelica",
    title: titleGinto,
    body: bodyGelica,
  },

  {
    slug: "aeonik_orbikular",
    title: titleAeonik,
    body: bodyOrbikular,
  },

  {
    slug: "jubel_satori",
    title: titleJubel,
    body: bodySatori,
  },

  {
    slug: "elysian_conforto",
    title: titleElysian,
    body: bodyConforto,
  },

  {
    slug: "ssonder_satori",
    title: titleSsonder,
    body: bodySatori,
  },

  {
    slug: "onsra_conforto",
    title: titleOnsra,
    body: bodyConforto,
  },

  {
    slug: "altklarinet_altsystema",
    title: titleALTKlarinet,
    body: bodyALTSystema,
  },

  {
    slug: "altmariaclara_altriviera",
    title: titleALTMariaClara,
    body: bodyALTRiviera,
  },

  {
    slug: "altrigatoni_altriviera",
    title: titleALTRigatoni,
    body: bodyBastardoGrotesk,
  },

  {
    slug: "mullet_fabioxm",
    title: titleMullet,
    body: bodyFabioXM,
  },

  {
    slug: "revetra_ufficiosans",
    title: titleRevetra,
    body: bodyUfficioSans,
  },

  {
    slug: "cormorant_publicsans",
    title: titleCormorant,
    body: bodyPublicSans,
  },

  {
    slug: "futura_bookmania",
    title: titleFutura,
    body: bodyBookmania,
  },

  {
    slug: "ivypresto_messinasans",
    title: titleIvyPrestoDisplay,
    body: bodyMessinaSans,
  },

  {
    slug: "bebasneue_overusedgrotesk",
    title: titleBebasNeue,
    body: bodyOverusedGrotesk,
  },

  {
    slug: "proximasera_proximanova",
    title: titleProximaSera,
    body: bodyProximaNova,
  },

  {
    slug: "moderatextended_moderat",
    title: titleModeratExtended,
    body: bodyModerat,
  },

  {
    slug: "dmsans_spacegrotesk",
    title: titleDMSans,
    body: bodySpaceGrotesk,
  },

  {
    slug: "desire_gillsansnova",
    title: titleDesirePro,
    body: bodyGillSansNova,
  },

  {
    slug: "dmsans_spacegrotesk",
    title: titleArizonaFlare,
    body: bodyRom,
  },

  {
    slug: "oswald_playfairdisplay",
    title: titleOswald,
    body: bodyPlayfairDisplay,
  },

  {
    slug: "aktivgrotesk_aktivgrotesk",
    title: titleAktivGrotesk,
    body: bodyAktivGrotesk,
  },

  {
    slug: "ppeiko_ppfragmentsans",
    title: titlePPEiko,
    body: bodyPPFragmentSans,
  },

  {
    slug: "moderat_moderat",
    title: titleModerat,
    body: bodyModerat,
  },

  {
    slug: "oswald_futura",
    title: titleOswald,
    body: bodyFutura,
  },

  {
    slug: "geist_geist",
    title: titleGeist,
    body: bodyGeist,
  },

  {
    slug: "dmsans_dmsans",
    title: titleDMSans,
    body: bodyDMSans,
  },

  {
    slug: "proximanova_lora",
    title: titleProximaNova,
    body: bodyLora,
  },

  {
    slug: "neuemontreal_neuemontreal",
    title: titlePPNeueMontreal,
    body: bodyPPNeueMontreal,
  },

  {
    slug: "excon_generalsans",
    title: titleExcon,
    body: bodyGeneralSans,
  },

  {
    slug: "ebgaramond_montserrat",
    title: titleAdobeGaramond,
    body: bodyMontserrat,
  },

  {
    slug: "abcgravity_dmsans",
    title: titleABCGravityExt,
    body: bodyDMSans,
  },

  {
    slug: "bwfusionaitalic_bwnistagrot",
    title: titleBwFusionaItalic,
    body: bodyBwNistaGrot,
  },

  {
    slug: "bwgradual_bwmodelica",
    title: titleBwGradual,
    body: bodyBwModelica,
  },

  {
    slug: "bwdarius_bwseidoraw",
    title: titleBwDarius,
    body: bodyBwSeidoRaw,
  },

  {
    slug: "whempire_whgeometric",
    title: titleWorkhorseEmpire,
    body: bodyWorkhorseGeometric,
  },

  {
    slug: "whscript_itcfranklingothic",
    title: titleWorkhorseScript,
    body: bodyITCFranklinGothic,
  },

  {
    slug: "whgothic2_whgeomono",
    title: titleWorkhorseGothic2,
    body: bodyWorkhorseGeoMono,
  },

  {
    slug: "canela_blanchard",
    title: titleCanela,
    body: bodyBlanchard,
  },

  {
    slug: "marrsans_moulin",
    title: titleMarrSans,
    body: bodyMoulin,
  },

  {
    slug: "lyon_atlasgrotesk",
    title: titleLyon,
    body: bodyAtlasGrotesk,
  },

  {
    slug: "terzareader_delegate",
    title: titleTerzaReader,
    body: bodyDelegate,
  },

  {
    slug: "moulin_atlasgrotesk",
    title: titleMoulin,
    body: bodyAtlasGrotesk,
  },

  {
    slug: "successor_canela",
    title: titleSuccessor,
    body: bodyCanela,
  },
];

export const allFonts = fontPairs.reduce((acc, pair) => {
  if (!acc.find((font) => font["font-family"] === pair.title["font-family"])) {
    acc.push(pair.title);
  }
  if (!acc.find((font) => font["font-family"] === pair.body["font-family"])) {
    acc.push(pair.body);
  }
  return acc;
}, [] as any[]);

const freeFonts = allFonts.filter((font) => font.source === "free");
const adobeFonts = allFonts.filter((font) => font.source === "adobe");
const fontsFromFoundries = allFonts.filter((font) => font.source === "custom");
const foundriesNames = [
  ...new Set(fontsFromFoundries.map((font) => font.foundry)),
];

export const fontStats = {
  count: {
    all: allFonts.length,
    free: freeFonts.length,
    adobe: adobeFonts.length,
    custom: fontsFromFoundries.length,
  },
  foundries: foundriesNames,
};
