import { useHotkeys, useLocalStorage, useSessionStorage } from "@mantine/hooks";
import { createContext, useContext, useState } from "react";
import { openModalLoginIfNeeded } from "../components/ModalLogin";
import { useUser } from "./UserContext";
import { InspoEvent, LifetimeModalTrigger, track } from "../utils";
import { colorSchemes } from "src/utils/colorScheme/palette";
import { openModalLifetimeIfNeeded } from "../components/ModalLifetime";

interface ColorSchemeContextType {
  colorSchemeIndex: number;
  setColorSchemeIndex: (params: {
    index: number;
    shouldSaveHistory?: boolean;
  }) => void;
  customColorSchemeIsActive: boolean;
  setCustomColorSchemeIsActive: (params: {
    isActive: boolean;
    shouldSaveHistory?: boolean;
  }) => void;
  customColorScheme: any;
  setCustomColorScheme: (params: {
    scheme: any;
    shouldSaveHistory?: boolean;
  }) => void;
  originColorScheme: any;
  setOriginColorScheme: (scheme: any) => void;
  currentColorScheme: any;
  colorIsLockedAtSlug: string | undefined;
  setColorAtSlug: (params: {
    slug: string;
    shouldLock: boolean;
    shouldSaveHistory?: boolean;
  }) => void;
  handleColorSchemeChange: (change: "back" | "next" | "random") => void;
  toggleLockedColorScheme: () => void;
}

const ColorSchemeContext = createContext<ColorSchemeContextType | undefined>(
  undefined,
);

export function ColorSchemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user, isPro } = useUser();

  const [colorSchemeIndex, setColorSchemeIndex] = useLocalStorage({
    key: "color_scheme_index",
    defaultValue: 0,
    getInitialValueInEffect: true,
  });
  const [customColorSchemeIsActive, setCustomColorSchemeIsActive] =
    useSessionStorage({
      key: "custom_color_scheme_is_active",
      defaultValue: false,
    });
  const [customColorScheme, setCustomColorScheme] = useSessionStorage<
    any | undefined
  >({
    key: "custom_color_scheme",
    defaultValue: undefined,
  });
  const [originColorScheme, setOriginColorScheme] = useSessionStorage<
    any | undefined
  >({
    key: "origin_color_scheme",
    defaultValue: undefined,
  });

  useHotkeys([
    [
      "ArrowUp",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.arrowup",
          })
        ) {
          return;
        }

        if (
          openModalLifetimeIfNeeded({
            user,
            isPro,
            source: LifetimeModalTrigger.COLOR_PICKER,
          })
        ) {
          return;
        }

        track({
          event: "change_color",
          properties: { source: "keyboard", action: "back" },
        });
        handleColorSchemeChange("next");
      },
    ],
    [
      "ArrowDown",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.arrowdown",
          })
        ) {
          return;
        }

        if (
          openModalLifetimeIfNeeded({
            user,
            isPro,
            source: LifetimeModalTrigger.COLOR_PICKER,
          })
        ) {
          return;
        }

        track({
          event: "change_color",
          properties: { source: "keyboard", action: "next" },
        });
        handleColorSchemeChange("back");
      },
    ],
  ]);

  const [colorIsLockedAtSlug, setColorIsLockedAtSlug] = useState<
    string | undefined
  >(undefined);

  function setColorAtSlug({
    slug,
    shouldLock = false,
    shouldSaveHistory = false,
  }: {
    slug: string;
    shouldLock: boolean;
    shouldSaveHistory?: boolean;
  }) {
    if (slug) {
      const index = colorSchemes.findIndex((c) => c.slug === slug);
      setColorSchemeIndex(index !== -1 ? index : 0);
    }
    shouldLock
      ? setColorIsLockedAtSlug(slug)
      : setColorIsLockedAtSlug(undefined);
    if (shouldSaveHistory) {
      document.dispatchEvent(
        new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT),
      );
    }
  }

  const currentColorScheme =
    customColorSchemeIsActive && !!customColorScheme
      ? customColorScheme
      : colorSchemes[colorSchemeIndex];

  function handleColorSchemeChange(change: "back" | "next" | "random") {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "color.change",
      })
    ) {
      return;
    }

    if (colorIsLockedAtSlug !== undefined) return;

    let newIndex;
    switch (change) {
      case "back":
        newIndex =
          colorSchemeIndex === 0
            ? colorSchemes.length - 1
            : colorSchemeIndex - 1;
        break;
      case "next":
        newIndex =
          colorSchemeIndex === colorSchemes.length - 1
            ? 0
            : colorSchemeIndex + 1;
        break;
      case "random":
        newIndex = Math.floor(Math.random() * colorSchemes.length);
        break;
    }

    setCustomColorSchemeIsActive(false);
    setColorSchemeIndex(newIndex);
    document.dispatchEvent(new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT));
  }

  function toggleLockedColorScheme() {
    if (colorIsLockedAtSlug) {
      setColorIsLockedAtSlug(undefined);
    } else {
      setColorIsLockedAtSlug(currentColorScheme.slug);
    }
  }

  return (
    <ColorSchemeContext.Provider
      value={{
        colorSchemeIndex,
        setColorSchemeIndex: ({
          index,
          shouldSaveHistory = false,
        }: {
          index: number;
          shouldSaveHistory?: boolean;
        }) => {
          setColorSchemeIndex(index);
          setColorIsLockedAtSlug(undefined);
          if (shouldSaveHistory) {
            document.dispatchEvent(
              new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT),
            );
          }
        },
        customColorSchemeIsActive,
        setCustomColorSchemeIsActive: ({
          isActive,
          shouldSaveHistory = false,
        }: {
          isActive: boolean;
          shouldSaveHistory?: boolean;
        }) => {
          setCustomColorSchemeIsActive(isActive);
          if (shouldSaveHistory) {
            document.dispatchEvent(
              new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT),
            );
          }
        },
        customColorScheme,
        setCustomColorScheme: ({
          scheme,
          shouldSaveHistory = false,
        }: {
          scheme: any;
          shouldSaveHistory?: boolean;
        }) => {
          setCustomColorScheme(scheme);
          if (shouldSaveHistory) {
            document.dispatchEvent(
              new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT),
            );
          }
        },
        originColorScheme,
        setOriginColorScheme,
        currentColorScheme,
        colorIsLockedAtSlug,
        setColorAtSlug,
        handleColorSchemeChange,
        toggleLockedColorScheme,
      }}
    >
      {children}
    </ColorSchemeContext.Provider>
  );
}

export function useColorPalette() {
  const context = useContext(ColorSchemeContext);
  if (context === undefined) {
    throw new Error("useColorScheme must be used within a ColorSchemeProvider");
  }
  return context;
}
