import { Box, Chip, Divider, ScrollArea, Space, Text } from "@mantine/core";
import {
  upperFirst,
  useClipboard,
  useHover,
  useSessionStorage,
} from "@mantine/hooks";
import { IconBrush, IconEdit } from "@tabler/icons-react";
import { useEffect, useMemo, useRef } from "react";
import { colorSchemes } from "src/utils/colorScheme/palette";
import { useColorPalette } from "../contexts/ColorSchemeContext";
import { useUser } from "../contexts/UserContext";
import { LifetimeModalTrigger, track } from "../utils";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { Vertical } from "./mantine-layout-components/Vertical";
import { openModalLoginIfNeeded } from "./ModalLogin";
import { InspoChip } from "./ui/InspoChip";
import { InspoKeyboardShortcutTooltip } from "./ui/InspoKeyboardShortcutTooltip";
import { InspoPickerFilterSection } from "./ui/InspoPickerFilterSection";
import { InspoPickerListButton } from "./ui/InspoPickerListButton";
import { InspoToolbarActionIcon } from "./ui/InspoToolbarActionIcon";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";

export function ColorSchemePickerTabCurated({
  setTab,
  setShow,
}: {
  setTab: (value: "curated" | "custom") => void;
  setShow: (value: boolean) => void;
}) {
  const { user, isPro } = useUser();
  const {
    currentColorScheme,
    colorIsLockedAtSlug,
    setCustomColorScheme,
    setCustomColorSchemeIsActive,
    setOriginColorScheme,
    setColorAtSlug,
  } = useColorPalette();

  const groupedColorSchemes = useMemo(() => {
    return colorSchemes.reduce((acc, cur) => {
      if (!acc[cur.group]) {
        acc[cur.group] = [];
      }
      acc[cur.group].push(cur);
      return acc;
    }, {} as any);
  }, []);

  const [curatedColorSchemesCategories, setCuratedColorSchemesCategories] =
    useSessionStorage<string[]>({
      key: "curated_color_schemes_categories_v3",
      defaultValue: [],
    });

  const filteredGroupedColorSchemes = useMemo(() => {
    const categoriesToShow =
      curatedColorSchemesCategories.length === 0
        ? Object.keys(groupedColorSchemes)
        : curatedColorSchemesCategories;

    return Object.entries(groupedColorSchemes)
      .filter(([group]) => categoriesToShow.includes(group))
      .reduce((acc, [group, colorSchemesInGroup]) => {
        acc[group] = colorSchemesInGroup;
        return acc;
      }, {} as any);
  }, [groupedColorSchemes, curatedColorSchemesCategories]);

  const viewportRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const selectedIndex = Object.entries(filteredGroupedColorSchemes)
      .flatMap(([_, items]) => items)
      .findIndex((item: any) => item.slug === currentColorScheme?.slug);

    viewportRef.current
      ?.querySelectorAll("[data-list-item]")
      ?.[selectedIndex]?.scrollIntoView({ block: "center" });
  }, []);

  return (
    <Vertical fullW fullH gap={"sm"} style={{ zIndex: 3 }}>
      <Filters
        curatedColorSchemesCategories={curatedColorSchemesCategories}
        setCuratedColorSchemesCategories={setCuratedColorSchemesCategories}
        groupedColorSchemes={groupedColorSchemes}
      />

      <Divider w={"100%"} color={"#464444"} />

      <ScrollArea
        viewportRef={viewportRef}
        mt={"-16px"}
        style={{
          height: "425px",
          width: "100%",
        }}
      >
        {Object.entries(filteredGroupedColorSchemes).map(
          ([groupName, items], i) => {
            return (
              <Vertical gap={"xs"} mt={"sm"} key={i}>
                <Text
                  variant="paragraph-md"
                  c="rgba(168, 162, 158, 1)"
                  ta={"left"}
                >
                  {upperFirst(groupName)}
                </Text>
                {(items as any).map((item) => {
                  return (
                    <InspoPickerListButton
                      key={item.slug}
                      selected={currentColorScheme?.slug === item.slug}
                      onClick={(e) => {
                        if (
                          openModalLoginIfNeeded({
                            user,
                            isMobile: false,
                            source: "lock_color_scheme",
                          })
                        ) {
                          return;
                        }

                        if (
                          openModalLifetimeIfNeeded({
                            user,
                            isPro,
                            source: LifetimeModalTrigger.COLOR_PICKER,
                            additionalTrackProperties: {
                              source2:
                                LifetimeModalTrigger.COLOR_PICKER_CURATED,
                            },
                          })
                        ) {
                          return;
                        }

                        setColorAtSlug({
                          slug: item.slug,
                          shouldLock: false,
                          shouldSaveHistory: true,
                        });
                        setShow(false);

                        track({
                          event: "select_color_scheme",
                          properties: {
                            category: "curated",
                            group: groupName,
                            slug: item.slug,
                          },
                        });
                      }}
                    >
                      <ColorSchemeRow
                        palette={item}
                        selected={colorIsLockedAtSlug === item.slug}
                        onEditColorScheme={() => {
                          setCustomColorScheme({
                            scheme: item,
                            shouldSaveHistory: true,
                          });
                          setOriginColorScheme(item);
                          setCustomColorSchemeIsActive({
                            isActive: true,
                            shouldSaveHistory: false,
                          });
                          setTab("custom");
                        }}
                      />
                    </InspoPickerListButton>
                  );
                })}
              </Vertical>
            );
          },
        )}
      </ScrollArea>
    </Vertical>
  );
}

function Filters({
  curatedColorSchemesCategories,
  setCuratedColorSchemesCategories,
  groupedColorSchemes,
}: {
  curatedColorSchemesCategories: string[];
  setCuratedColorSchemesCategories: (value: string[]) => void;
  groupedColorSchemes: any;
}) {
  return (
    <Horizontal gap={"sm"} noWrap align="start">
      <InspoPickerFilterSection title="style" icon={<IconBrush />}>
        <Chip.Group
          multiple
          value={curatedColorSchemesCategories}
          onChange={setCuratedColorSchemesCategories}
        >
          <Horizontal gap={"xxs"}>
            {[
              ...Object.keys(groupedColorSchemes).map((key) => ({
                value: key,
                label: key.toUpperCase(),
              })),
            ].map((item) => (
              <InspoChip
                key={item.value}
                value={item.value}
                checked={curatedColorSchemesCategories.includes(item.value)}
              >
                {item.label}
              </InspoChip>
            ))}
          </Horizontal>
        </Chip.Group>
      </InspoPickerFilterSection>
    </Horizontal>
  );
}

function ColorSchemeRow({
  palette,
  selected,
  onEditColorScheme,
}: {
  palette;
  selected;
  onEditColorScheme;
}) {
  return (
    <Horizontal gap={2} fullW noWrap spaceBetween>
      <Text variant="paragraph-md" ta="left">
        {palette.name}
      </Text>

      <Horizontal
        noWrap
        fullW
        maw={200}
        gap={0}
        style={{
          border: "1px solid rgba(168, 162, 158, 1)",
          borderRadius: "var(--mantine-radius-xs)",
          overflow: "hidden",
        }}
      >
        <ColorSchemeColor hex={palette.dark.background} />
        <ColorSchemeColor hex={palette.bright.background} />
        <ColorSchemeColor hex={palette.light.background} />

        <Space />

        <InspoKeyboardShortcutTooltip
          label={"Edit color scheme"}
          shortcut={undefined}
        >
          <InspoToolbarActionIcon
            show={true}
            icon={<IconEdit color={selected ? "black" : "white"} />}
            wrapperProps={{ h: 16, w: 16, mr: "xxs", ml: "xs" }}
            onClick={onEditColorScheme}
          />
        </InspoKeyboardShortcutTooltip>
      </Horizontal>
    </Horizontal>
  );
}

function ColorSchemeColor({ hex }: { hex: string }) {
  const { hovered, ref } = useHover();
  const { copy, copied } = useClipboard();

  return (
    <Box w={`${100 / 3}%`} h={20} bg={copied ? "black" : hex} ref={ref}>
      {hovered && (
        <Text
          variant="label-md"
          w={"100%"}
          mt={"xxs"}
          ta={"center"}
          c={copied ? "white" : "black"}
          style={{
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            copy(hex);
          }}
        >
          {copied ? "COPIED" : hex}
        </Text>
      )}
    </Box>
  );
}
