import { Box, Text } from "@mantine/core";
import { useClickOutside, useHover, useSessionStorage } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp, IconLock } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { LifetimeModalTrigger, track } from "../utils";
import style from "./../../../styles/CustomComponents.module.scss";
import { ColorSchemePicker } from "./ColorSchemePicker";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { Vertical } from "./mantine-layout-components/Vertical";
import { InspoActionIcon } from "./ui/InspoActionIcon";
import { InspoKeyboardShortcutTooltip } from "./ui/InspoKeyboardShortcutTooltip";
import { InspoToolbarActionIcon } from "./ui/InspoToolbarActionIcon";
import { InspoToolbarDivider } from "./ui/InspoToolbarDivider";
import { useUser } from "../contexts/UserContext";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";
import { openModalLoginIfNeeded } from "./ModalLogin";
import { useColorPalette } from "../contexts/ColorSchemeContext";

export const ColorSchemeSection = ({}: {}) => {
  const { user, isPro } = useUser();
  const { hovered: hoveredColor, ref: refColorHover } = useHover();
  const [showColorSchemePicker, setShowColorSchemePicker] = useState(false);

  const {
    customColorSchemeIsActive,
    currentColorScheme,
    colorIsLockedAtSlug,
    setColorAtSlug,
    setCustomColorSchemeIsActive,
    handleColorSchemeChange,
    toggleLockedColorScheme,
  } = useColorPalette();

  const refColorSchemePicker = useClickOutside(
    () => setShowColorSchemePicker(false),
    ["mousedown", "touchstart"],
    [
      window.document.getElementById("color-scheme-picker"),
      // @ts-ignore
      ...Array.from(document.querySelectorAll("[data-portal]")),
    ],
  );

  const shouldShowColorLock = hoveredColor || colorIsLockedAtSlug !== undefined;

  const [tab, setTab] = useSessionStorage<"curated" | "custom">({
    key: `color_scheme_picker_tab`,
    defaultValue: "curated",
  });

  useEffect(() => {
    if (customColorSchemeIsActive) {
      setTab("custom");
    } else {
      setTab("curated");
    }
  }, [customColorSchemeIsActive]);

  return (
    <Horizontal
      classNames={{ root: style.bottomBarSectionRoot }}
      gap={"sm"}
      noWrap
      ref={refColorSchemePicker}
    >
      <Text variant="label-lg">COLOR</Text>

      {showColorSchemePicker && (
        <ColorSchemePicker
          tab={tab}
          setTab={setTab}
          setShow={setShowColorSchemePicker}
        />
      )}

      <Horizontal gap={0} h={44} center noWrap>
        <Vertical
          ref={refColorHover as any}
          w={120}
          bg={"white"}
          h={44}
          style={{
            borderRadius: "var(--mantine-radius-xs)",
            outline: hoveredColor ? "2px solid #000" : undefined,
            cursor: "pointer",
          }}
          centerH
          onClick={() => {
            if (
              openModalLoginIfNeeded({
                user,
                isMobile: false,
                source: "toggle_font_picker",
              })
            ) {
              return;
            }
            setShowColorSchemePicker(!showColorSchemePicker);
          }}
        >
          <InspoKeyboardShortcutTooltip
            label={"Lock palette"}
            shortcut={undefined}
          >
            <InspoToolbarActionIcon
              show={shouldShowColorLock}
              icon={<IconLock />}
              wrapperProps={{
                pos: "absolute",
                ml: "xxs",
              }}
              onClick={() => {
                if (
                  openModalLoginIfNeeded({
                    user,
                    isMobile: false,
                    source: "lock_color_scheme",
                  })
                ) {
                  return;
                }

                if (
                  openModalLifetimeIfNeeded({
                    user,
                    isPro,
                    source: LifetimeModalTrigger.COLOR_LOCK,
                  })
                ) {
                  return;
                }

                track({
                  event: "toggle_color_lock",
                  properties: {},
                });

                toggleLockedColorScheme();
              }}
            />
          </InspoKeyboardShortcutTooltip>

          <Horizontal
            key={currentColorScheme.slug}
            gap={0}
            ml={"xl"}
            style={{
              borderRadius: "var(--mantine-radius-xxs)",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#B5B5B5",
              overflow: "hidden",
            }}
          >
            {[
              currentColorScheme.dark.background,
              currentColorScheme.light.background,
              currentColorScheme.bright.background,
            ].map((c, i) => {
              return <Box key={`color-${c}-${i}`} bg={c} w={20} h={20} />;
            })}
          </Horizontal>
        </Vertical>

        <InspoToolbarDivider />

        <InspoKeyboardShortcutTooltip
          label={"Previous/Next color palette"}
          shortcut={["↑", "↓"]}
        >
          <Vertical
            gap={"xxxs"}
            h={52}
            centerH
            mr={-4}
            style={{
              borderTopRightRadius: "calc(var(--mantine-radius-xs) + 2px)",
              borderBottomRightRadius: "calc(var(--mantine-radius-xs) + 2px)",
              overflow: "hidden",
            }}
          >
            <InspoActionIcon
              theme="dark"
              icon={<IconChevronUp />}
              size={25}
              radius={0}
              w={29}
              onClick={() => {
                handleColorSchemeChange("back");
                track({
                  event: "change_color",
                  properties: { source: "button", action: "back" },
                });
              }}
            />
            <InspoActionIcon
              theme="dark"
              icon={<IconChevronDown />}
              size={25}
              radius={0}
              w={29}
              onClick={() => {
                handleColorSchemeChange("next");
                track({
                  event: "change_color",
                  properties: { source: "button", action: "next" },
                });
              }}
            />
          </Vertical>
        </InspoKeyboardShortcutTooltip>
      </Horizontal>
    </Horizontal>
  );
};
