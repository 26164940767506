import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/core/styles/global.css";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import * as Sentry from '@sentry/react';
import { Suspense, useMemo } from "react";
import { theme } from "src/styles/mantine-theme";
import Home from ".";
import { FullPageLoader } from "./components/FullPageLoader";
import {
  ModalLifetime,
  ModalPreLifetime,
  ModalPreLifetimeWithWait,
} from "./components/ModalLifetime";
import { ModalLogin } from "./components/ModalLogin";
import { ModalUpdates } from "./components/ModalUpdates";
import { ModalSaved } from "./components/savedModal/ModalSaved";
import { CardsStateProvider } from "./contexts/CardsStateContext";
import { ColorSchemeProvider } from "./contexts/ColorSchemeContext";
import { FoldersProvider } from "./contexts/FoldersContext";
import { FontsProvider } from "./contexts/FontsContext";
import { IndustryProvider } from "./contexts/IndustryContext";
import { UserProvider } from "./contexts/UserContext";
import { useIsMobile } from "./hooks/useIsMobile";
import { ErrorBoundarySection } from "./components/ErrorBoundarySection";

import "./../../styles/fontImports.scss";
import "./../../styles/globals.css";


export default function App({
  user,
  isPro,
  isProd,
}: {
  user: any;
  isPro: boolean;
  isProd: boolean;
}) {
  const isMobile = useIsMobile();

  const currentTheme = useMemo(() => {
    if (!isMobile) return theme;

    return {
      ...theme,
      fontSizes: {
        xs: "6px",
        sm: "8px",
        md: "10px",
        lg: "12px",
        xl: "18px",
        xxl: "21px",
        xxxl: "25px",
      },
    };
  }, [isMobile]);

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundarySection}>
    <MantineProvider theme={currentTheme}>
      <UserProvider user={user} isPro={isPro} isProd={isProd}>
        <FoldersProvider>
          <ModalsProvider
            modals={{
              login: ModalLogin,
              lifetime: ModalLifetime,
              pre_lifetime: ModalPreLifetime,
              pre_lifetime_with_wait: ModalPreLifetimeWithWait,
              updates: ModalUpdates,
              saved: ModalSaved,
            }}
          >
            <IndustryProvider>
              <CardsStateProvider>
                <ColorSchemeProvider>
                  <FontsProvider>
                    <Notifications position="top-right" zIndex={1000} />
                    <Suspense fallback={<FullPageLoader />}>
                      <Home />
                    </Suspense>
                  </FontsProvider>
                </ColorSchemeProvider>
              </CardsStateProvider>
            </IndustryProvider>
          </ModalsProvider>
        </FoldersProvider>
      </UserProvider>
    </MantineProvider>
    </Sentry.ErrorBoundary>
  );
}