import { Box, Button, Divider, Text } from "@mantine/core";
import React from "react";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import { InspoCardText } from "../ui/InspoCardContent";

type CartContentProps = {
  title: string;
  items: {
    type: "price" | "deliverytime" | "material";
    title: string;
    value: string;
  }[];
  button: string;
};

export const cartContent = {
  fashion: {
    title:
      "Made in Italy from 100% organic cotton, crafted by hand and finished with care.",
    items: [
      { type: "price", title: "Price", value: "€570,00" },
      { type: "deliverytime", title: "Delivery time", value: "3 - 5 days" },
      { type: "material", title: "Material", value: "100% Organic Cotton" },
    ],
    button: "Shop now",
  },
  beauty: {
    title: "Limited Edition Luxury Skincare Set",
    items: [
      { type: "price", title: "Price", value: "$299.99" },
      { type: "deliverytime", title: "Delivery time", value: "2 - 3 days" },
      {
        type: "material",
        title: "Key Ingredients",
        value: "Retinol & Peptides",
      },
    ],
    button: "Add to Cart",
  },

  editorial: {
    title: "Annual Digital Subscription\nto Premium Magazine",
    items: [
      { type: "price", title: "Price", value: "$89.99/year" },
      { type: "deliverytime", title: "Access", value: "Immediate" },
      {
        type: "material",
        title: "Includes",
        value: "Exclusive Content + Archive",
      },
    ],
    button: "Subscribe Now",
  },

  finance: {
    title:
      "Personal Finance Consultation Session: Understanding Your Financial Journey and Building a Sustainable Future",
    items: [
      { type: "price", title: "Price", value: "$150/hour" },
      { type: "deliverytime", title: "Available slots", value: "Next week" },
      { type: "material", title: "Session Length", value: "90 Minutes" },
    ],
    button: "Book Appointment",
  },

  food: {
    title: "Gourmet Meal Kit Subscription\n(4 meals/week)",
    items: [
      { type: "price", title: "Price", value: "$79.99/week" },
      { type: "deliverytime", title: "First delivery", value: "This Friday" },
      { type: "material", title: "Portions", value: "2-4 People/Meal" },
    ],
    button: "Start Subscription",
  },

  sport: {
    title: "Pro Carbon Fiber Tennis Racket:\nTour Player Edition 2024",
    items: [
      { type: "price", title: "Price", value: "$299" },
      { type: "deliverytime", title: "Delivery", value: "2-3 Business Days" },
      { type: "material", title: "Weight", value: "300g Unstrung" },
    ],
    button: "Add to Cart",
  },
} as const satisfies Record<string, CartContentProps>;

export default function Cart({
  colorScheme,
  content,
  ...props
}: InspoTypeCardProps<CartContentProps> & BentoCardChildrenProps) {
  if (!content) return null;

  return (
    <Vertical
      fullW
      fullH
      bg={colorScheme.background}
      spaceBetween
      px={"sm"}
      py={"sm"}
      gap={"xs"}
      {...props}
    >
      <InspoCardText
        variant="card-paragraph-lg"
        c={colorScheme.textdark}
        ta={"start"}
        textId="title"
        {...props}
      >
        {content.title}
      </InspoCardText>

      <Vertical fullW>
        <Vertical fullW gap={"xs"}>
          {content.items.map((el, i) => (
            <React.Fragment key={i}>
              <Divider w={"100%"} color={colorScheme.textdark} opacity={0.5} />

              <Horizontal fullW spaceBetween centerV>
                <InspoCardText
                  variant={"card-paragraph-md"}
                  c={colorScheme.textdark}
                  textId={`item-${i}-title`}
                  {...props}
                >
                  {el.title}
                </InspoCardText>

                <InspoCardText
                  variant="card-paragraph-md"
                  c={colorScheme.textdark}
                  textId={`item-${i}-value`}
                  {...props}
                >
                  {el.value}
                </InspoCardText>
              </Horizontal>
            </React.Fragment>
          ))}
        </Vertical>
      </Vertical>

      <Button
        bg={colorScheme.ctadark}
        radius={"xxs"}
        w={"100%"}
        style={{
          border: "none",
        }}
      >
        <Text
          variant="card-paragraph-md"
          c={colorScheme.textlight}
          ta={"center"}
        >
          {content.button}
        </Text>
      </Button>
    </Vertical>
  );
}
