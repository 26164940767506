import { Text, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Vertical } from "../mantine-layout-components/Vertical";
import { InspoCardText, InspoCardTitle } from "../ui/InspoCardContent";

type LaunchDoubleTitleContentProps = {
  label1: string;
  title1: string;
  paragraph: string;
  label2: string;
  title2: string;
};

export const launchDoubleTitleContent = {
  fashion: {
    label1: "Limited Edition / Exclusive ",
    title1: "New Collection Launch",
    paragraph: "Release Date\n12.12.2024",
    label2: "vision",
    title2: "Available Now",
  },
} as const satisfies Record<string, LaunchDoubleTitleContentProps>;

export default function LaunchDoubleTitle({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<LaunchDoubleTitleContentProps> & BentoCardChildrenProps) {
  if (!content) return null;

  return (
    <Vertical
      bg={colorScheme.background}
      fullW
      fullH
      centerH
      spaceBetween
      py={"lg"}
      px={"lg"}
      {...props}
    >
      <InspoCardText
        variant="card-label-lg"
        c={colorScheme.textdark}
        ta={"center"}
        w={"100%"}
        textId="label1"
        {...props}
      >
        {content.label1}
      </InspoCardText>
      <InspoCardTitle
        variant="card-headline-md"
        c={colorScheme.textdark}
        ta={"center"}
        w={"100%"}
        textId="title1"
        {...props}
      >
        {content.title1}
      </InspoCardTitle>

      <InspoCardText
        variant="card-paragraph-md"
        c={colorScheme.textdark}
        ta={"center"}
        w={"100%"}
        textId="paragraph"
        {...props}
      >
        {content.paragraph}
      </InspoCardText>

      <InspoCardTitle
        variant="card-headline-xs"
        c={colorScheme.textdark}
        ta={"center"}
        w={"100%"}
        textId="title2"
        {...props}
      >
        {content.title2}
      </InspoCardTitle>
    </Vertical>
  );
}
