import { Image, Text, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrl,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import { InspoCardText, InspoCardTitle } from "../ui/InspoCardContent";

type BlogArticleWithImageLeftContentProps = {
  title: string;
  subtitle: string;
  labelBelowSubtitle: string;
  imgs: string[];
  alt: string;
};

export const blogArticleWithImageLeftContent = {
  food: {
    title: "Eat The Rainbow",
    subtitle:
      "A new restaurant in the heart of the city that serves up delicious food and original drinks.",
    labelBelowSubtitle: "Read more",
    imgs: images.food.map((img) =>
      cloudinaryUrl(img, {
        folder: "food",
        transformation: "bento_square",
      }),
    ),
    alt: "Product Image",
  },
  sport: {
    title: "Moving Forward",
    subtitle:
      "We take action to create a future of continual progress for athletes, sport and our world.",
    labelBelowSubtitle: "Read more",
    imgs: images.sport.map((img) =>
      cloudinaryUrl(img, {
        folder: "sport",
        transformation: "bento_square",
      }),
    ),
    alt: "Product Image",
  },

  beauty: {
    title: "Tradition Backed by Science",
    subtitle:
      "Inspired by moments of care.\nWe honor those who put the needs of others before their own.",
    labelBelowSubtitle: "Read more",
    imgs: images.beauty.map((img) =>
      cloudinaryUrl(img, {
        folder: "beauty",
        transformation: "bento_square",
      }),
    ),
    alt: "Product Image",
  },

  finance: {
    title: "Building Your Future",
    subtitle:
      "Empowering individuals and businesses to take control of their financial future with innovative solutions.",
    labelBelowSubtitle: "Read more",
    imgs: images.finance.map((img) =>
      cloudinaryUrl(img, {
        folder: "finance",
        transformation: "bento_square",
      }),
    ),
    alt: "Product Image",
  },

  fashion: {
    title: "Defining Style",
    subtitle:
      "A fusion of creativity and craftsmanship. We bring timeless pieces that elevate everyday fashion.",
    labelBelowSubtitle: "Read more",
    imgs: images.fashion.map((img) =>
      cloudinaryUrl(img, {
        folder: "fashion",
        transformation: "bento_square",
      }),
    ),
    alt: "Product Image",
  },

  editorial: {
    title: "The Future of Sustainable Architecture",
    subtitle:
      "Exploring how modern architects are revolutionizing urban development with eco-friendly designs and renewable materials.",
    labelBelowSubtitle: "Read more",
    imgs: images.editorial.map((img) =>
      cloudinaryUrl(img, {
        folder: "editorial",
        transformation: "bento_square",
      }),
    ),
    alt: "Product Image",
  },
} as const satisfies Record<string, BlogArticleWithImageLeftContentProps>;

export default function BlogArticleWithImageLeft({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<BlogArticleWithImageLeftContentProps> &
  BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Horizontal
      fullW
      fullH
      center
      bg={colorScheme.background}
      px={"xs"}
      py={"xs"}
      gap={0}
      noWrap
      {...props}
    >
      <Image
        src={uploadedImagePath ?? content.imgs[imgIndex]}
        alt={content.alt}
        radius={"xxs"}
        fit="cover"
        style={{ flexGrow: 1 }}
        h={"100%"}
        w={"50%"}
      />
      <Vertical gap={"lg"} px={"lg"} maw={"50%"}>
        <InspoCardTitle
          variant="card-headline-md"
          c={colorScheme.textdark}
          ta={"center"}
          w={"100%"}
          textId="title"
          {...props}
        >
          {content.title}
        </InspoCardTitle>

        <InspoCardText
          variant="card-paragraph-md"
          c={colorScheme.textdark}
          ta={"center"}
          textId="subtitle"
          {...props}
        >
          {content.subtitle}
        </InspoCardText>

        <InspoCardText
          variant="card-label-sm"
          c={colorScheme.textdark}
          mx={"auto"}
          textId="labelBelowSubtitle"
          {...props}
        >
          {content.labelBelowSubtitle}
        </InspoCardText>
      </Vertical>
    </Horizontal>
  );
}
