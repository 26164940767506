import { useLocalStorage } from "@mantine/hooks";
import React, {
  createContext,
  useCallback,
  useContext,
  type ReactNode,
} from "react";

type BentoCardChildrenProps = Record<string, any>;

interface CardsStateContextType {
  cardsState: Record<string, BentoCardChildrenProps>;
  setCardsState: React.Dispatch<
    React.SetStateAction<Record<string, BentoCardChildrenProps>>
  >;
  setCardState: (id: string, state: Partial<BentoCardChildrenProps>) => void;
  visibleCardsKeys: string[];
  setVisibleCardsKeys: React.Dispatch<React.SetStateAction<string[]>>;
}

const CardsStateContext = createContext<CardsStateContextType | undefined>(
  undefined,
);

interface CardsStateProviderProps {
  children: ReactNode;
}

export const CardsStateProvider: React.FC<CardsStateProviderProps> = ({
  children,
}) => {
  const [cardsState, setCardsState] = useLocalStorage<
    Record<string, BentoCardChildrenProps>
  >({
    key: "cards_state",
    defaultValue: {},
  });

  const setCardState = useCallback(
    (id: string, state: Partial<BentoCardChildrenProps>) => {
      setCardsState((prevState) => ({
        ...prevState,
        [id]: { ...prevState[id], ...state },
      }));
    },
    [setCardsState],
  );

  const [visibleCardsKeys, setVisibleCardsKeys] = useLocalStorage<string[]>({
    key: "visible_cards_keys",
    defaultValue: [],
  });

  return (
    <CardsStateContext.Provider
      value={{
        cardsState,
        setCardsState,
        setCardState,
        visibleCardsKeys,
        setVisibleCardsKeys,
      }}
    >
      {children}
    </CardsStateContext.Provider>
  );
};

export function useCardsState() {
  const context = useContext(CardsStateContext);
  if (!context) {
    throw new Error("useCardsState must be used within a CardsStateProvider");
  }
  return context;
}
