export function track({ event, properties }) {
  // https://posthog.com/tutorials/event-tracking-guide#setting-up-custom-events

  // @ts-ignore
  window.posthog?.capture(event, properties);
}

export function debounce(func: Function, delay: number) {
  let timeoutId: NodeJS.Timeout;
  const debouncedFunction = function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };

  debouncedFunction.cancel = function () {
    clearTimeout(timeoutId);
  };

  return debouncedFunction;
}

export type IndustryType = (typeof industries)[number];

export enum InspoEvent {
  RANDOM = "random_everything",
  OPEN_LOGIN_MODAL = "open_login_modal",
  SET_HISTORY_CHECKPOINT = "set_history_checkpoint",
  RESET_CUSTOM_TEXT = "reset_custom_text",
}

export const industries = [
  { slug: "foodNDrink", label: "Food & Drink", isPro: false },
  { slug: "ecommerce", label: "Fashion", isPro: false },
  { slug: "beauty", label: "Beauty", isPro: true },
  { slug: "editorial", label: "Editorial", isPro: true },
  { slug: "finance", label: "Finance", isPro: true },
  { slug: "sport", label: "Sport", isPro: true },
];

export const ModalKind = {
  LOGIN: "login",
  LIFETIME: "lifetime",
  PRE_LIFETIME: "pre_lifetime",
  PRE_LIFETIME_WITH_WAIT: "pre_lifetime_with_wait",
  SAVED: "saved",
  UPDATES: "updates",
};

export const LoginModalTrigger = {
  LIFETIME_MODAL: "lifetime",
};

export const LifetimeModalTrigger = {
  CUSTOM_TEXTS: "custom_texts",
  ADD_TO_FOLDER: "add_to_folder",
  ADD_TO_NEW_FOLDER: "add_to_new_folder",
  SAVE_MOODBOARD_IMG: "save_moodboard_img",
  INDUSTRY_SECTION: "industry",
  COLOR_LOCK: "lock_color_scheme",
  FONT_LOCK_HEADING: "lock_heading",
  FONT_LOCK_BODY: "lock_body",
  CTA: "cta",
  PRE_LIFETIME_CTA: "pre_lifetime_cta",
  PRE_LIFETIME_WITH_WAIT_CTA: "pre_lifetime_with_wait_cta",
  FONT_PICKER: "font_picker",
  FONT_PICKER_LOCAL: "font_picker_local",
  FONT_PICKER_CURATED: "font_picker_curated",
  COLOR_PICKER: "color_picker",
  COLOR_PICKER_CUSTOM: "color_picker_custom",
  COLOR_PICKER_CURATED: "color_picker_curated",
  CHANGE_CARD: "change_card",
  UPLOAD_IMAGE: "upload_image",
  SHUFFLE_CARD_IMAGE: "shuffle_card_image",
  IMAGE_LOCK: "image_lock",
  RANDOM: "random",
  HISTORY: "history",
};

export const LIFETIME_MODAL_ALWAYS_SHOW_SOURCES = [
  LifetimeModalTrigger.CUSTOM_TEXTS,
  LifetimeModalTrigger.UPLOAD_IMAGE,
  LifetimeModalTrigger.SAVE_MOODBOARD_IMG,
  LifetimeModalTrigger.INDUSTRY_SECTION,
  LifetimeModalTrigger.ADD_TO_FOLDER,
  LifetimeModalTrigger.ADD_TO_NEW_FOLDER,
  LifetimeModalTrigger.COLOR_PICKER_CUSTOM,
  LifetimeModalTrigger.CTA,
] as const;
