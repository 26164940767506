import { BackgroundImage, Overlay, Text, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import { OverlayedBackgroundImage } from "../ui/InspoOverlayedBackgroundImage";
import { InspoCardText, InspoCardTitle } from "../ui/InspoCardContent";

type HeaderBackgroundHalfContentProps = {
  title1: string;
  web: string;
  subtitle: string;
  label1: string;
  label2: string;
  label3: string;
  imgs: string[];
  alt: string;
  align: "center" | "bottomLeft";
};

export const headerBackgroundHalfContent = {
  fashion: {
    title1: "Fashion\nParadigm",
    web: "chroma.couture",
    subtitle: "Textiles\nProduction",
    label1: "[1] automated fabric cutting",
    label2: "[2] eco-friendly dyeing",
    label3: "[3] supply chain optimization",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },
  food: {
    title1: "Culinary\nInnovation",
    web: "taste.lab",
    subtitle: "Smart\nKitchens",
    label1: "[1] AI recipe optimization",
    label2: "[2] waste reduction system",
    label3: "[3] automated inventory control",
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_landscape",
    }),
    alt: "Kitchen Innovation Image",
    align: "bottomLeft",
  },
  beauty: {
    title1: "Glow\nAlchemy",
    web: "glow.science",
    subtitle: "Smart\nFormulation",
    label1: "[1] personalized skincare",
    label2: "[2] bio-active ingredients",
    label3: "[3] molecular testing",
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_landscape",
    }),
    alt: "Beauty Lab Image",
    align: "bottomLeft",
  },
  editorial: {
    title1: "The last\nwild place",
    web: "story.sphere",
    subtitle: "Featured\nReport",
    label1: "[1] exclusive interviews",
    label2: "[2] in-depth analysis",
    label3: "[3] interactive features",
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_landscape",
    }),
    alt: "Editorial Studio Image",
    align: "bottomLeft",
  },
  finance: {
    title1: "Future\nBanking",
    web: "neo.finance",
    subtitle: "Digital\nSolutions",
    label1: "[1] blockchain integration",
    label2: "[2] smart contracts",
    label3: "[3] predictive analytics",
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_landscape",
    }),
    alt: "Finance Technology Image",
    align: "bottomLeft",
  },
  sport: {
    title1: "Athletic\nScience",
    web: "peak.tech",
    subtitle: "Performance\nAnalytics",
    label1: "[1] motion tracking",
    label2: "[2] biometric monitoring",
    label3: "[3] recovery optimization",
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_landscape",
    }),
    alt: "Sports Technology Image",
    align: "bottomLeft",
  },
} as const satisfies Record<string, HeaderBackgroundHalfContentProps>;

export default function HeaderHalfBackgroundHalf({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<HeaderBackgroundHalfContentProps> &
  BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <OverlayedBackgroundImage
      overlayOpacity={0.6}
      src={uploadedImagePath ?? content.imgs[imgIndex]}
      style={{
        objectFit: "cover",
        aspectRatio: "1/1",
      }}
      radius={"xxs"}
      w={"100%"}
      h={"100%"}
      {...props}
    >
      <Horizontal fullW fullH spaceBetween gap={0} noWrap {...props}>
        <Vertical
          gap={"xs"}
          px={"md"}
          py={"md"}
          w={"50%"}
          fullH
          justify="flex-end"
          style={{ zIndex: 1 }}
        >
          <InspoCardText
            variant="card-label-sm"
            c={colorScheme.textcontrast}
            ta={"left"}
            textId="web"
            {...props}
          >
            {content.web}
          </InspoCardText>

          <InspoCardTitle
            variant="card-display-md"
            c={colorScheme.textcontrast}
            ta={"left"}
            w={"100%"}
            textId="title1"
            {...props}
          >
            {content.title1}
          </InspoCardTitle>
        </Vertical>

        <Vertical
          gap={"xs"}
          px={"md"}
          py={"md"}
          w={"40%"}
          fullH
          spaceBetween
          style={{ zIndex: 1 }}
          bg={colorScheme.background}
        >
          <InspoCardTitle
            variant="card-headline-xs"
            c={colorScheme.textdark}
            textId="subtitle"
            {...props}
          >
            {content.subtitle}
          </InspoCardTitle>
          <Vertical gap={"xs"}>
            <InspoCardText
              variant="card-label-sm"
              c={colorScheme.textdark}
              ta={"left"}
              textId="label1"
              {...props}
            >
              {content.label1}
            </InspoCardText>
            <InspoCardText
              variant="card-label-sm"
              c={colorScheme.textdark}
              ta={"left"}
              textId="label2"
              {...props}
            >
              {content.label2}
            </InspoCardText>
            <InspoCardText
              variant="card-label-sm"
              c={colorScheme.textdark}
              ta={"left"}
              textId="label3"
              {...props}
            >
              {content.label3}
            </InspoCardText>
          </Vertical>
        </Vertical>
      </Horizontal>
    </OverlayedBackgroundImage>
  );
}
