import { ActionIcon, Button, Menu, Text, UnstyledButton } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconBookmark,
  IconBookmarkFilled,
  IconCheck,
  IconChevronDown,
  IconCirclePlus,
  IconPhotoDown,
  IconShare,
  IconShare3,
  IconX,
} from "@tabler/icons-react";
import html2canvas from "html2canvas";
import { useEffect, useMemo, useState } from "react";
import { colorSchemes } from "src/utils/colorScheme/palette";
import { useCardsState } from "../contexts/CardsStateContext";
import { useColorPalette } from "../contexts/ColorSchemeContext";
import { useFolders } from "../contexts/FoldersContext";
import { useFonts } from "../contexts/FontsContext";
import { useIndustry } from "../contexts/IndustryContext";
import { useUser } from "../contexts/UserContext";
import { useBentoCards } from "../hooks/useBentoCards";
import { industries, LifetimeModalTrigger, track } from "../utils";
import style from "./../../../styles/CustomComponents.module.scss";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";
import { openModalLoginIfNeeded } from "./ModalLogin";
import { openModalUpdates } from "./ModalUpdates";
import {
  openModalSaved,
  updateMoodboards,
  type SavedFolder,
} from "./savedModal/ModalSaved";
import { InspoActionIcon } from "./ui/InspoActionIcon";
import { InspoKeyboardShortcutTooltip } from "./ui/InspoKeyboardShortcutTooltip";
import { getPatchedCanvasForHtml2Canvas } from "src/lib/utils";

export function hasShareParams() {
  const searchParams = new URLSearchParams(document.location.search);
  const hasShareParams =
    searchParams.has("fonts") ||
    searchParams.has("palette") ||
    searchParams.has("industry") ||
    searchParams.has("titleIsLockedAt") ||
    searchParams.has("titleIsLockedAtFont") ||
    searchParams.has("titleIsLocal") ||
    searchParams.has("bodyIsLockedAt") ||
    searchParams.has("bodyIsLockedAtFont") ||
    searchParams.has("bodyIsLocal") ||
    searchParams.has("colorIsLockedAtSlug") ||
    searchParams.has("customColorScheme") ||
    searchParams.has("originColorScheme") ||
    searchParams.has("cardsState") ||
    searchParams.has("visibleCardsKeys");

  return hasShareParams;
}

export function hasOpenNewsletterModalParam() {
  const searchParams = new URLSearchParams(document.location.search);
  const hasOpenNewsletterModalParam = searchParams.get("newsletter");
  return (
    hasOpenNewsletterModalParam !== undefined &&
    hasOpenNewsletterModalParam !== null
  );
}

export const ShareSection = ({
  version = "desktop",
}: {
  version: "desktop" | "mobile";
}) => {
  const { user, isPro } = useUser();
  const { folders, refetchFolders } = useFolders();
  const { industry } = useIndustry();
  const { title, body } = useFonts();
  const { currentColorScheme: colorScheme } = useColorPalette();
  const shareUrl = useShareUrl();

  if (version === "mobile") {
    return <ShareSectionMobile onClick={() => {}} />;
  }

  const addedInFolders = folders
    .filter((folder) =>
      folder.moodboards.find((moodboard) => moodboard === shareUrl),
    )
    .map((folder) => ({
      id: folder.id,
    }));

  async function handleDownloadImage() {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "save_moodboard_img",
      })
    ) {
      return;
    }

    if (
      openModalLifetimeIfNeeded({
        user,
        isPro,
        source: LifetimeModalTrigger.SAVE_MOODBOARD_IMG,
      })
    ) {
      return;
    }

    const bentoElement = document.getElementById(
      "bento-container",
    ) as HTMLElement;
    if (!bentoElement) return;

    // Fixed dimensions for the moodboard image
    const dimensions = {
      width: 1920 / 1.5,
      height: 1080 / 1.5,
    };

    const clonedElement = bentoElement.cloneNode(true) as HTMLElement;
    const hiddenContainer = document.createElement("div");
    hiddenContainer.style.position = "absolute";
    hiddenContainer.style.left = "-9999px";
    hiddenContainer.style.top = "-9999px";
    hiddenContainer.style.width = `${dimensions.width}px`;
    hiddenContainer.style.height = `${dimensions.height}px`;
    hiddenContainer.appendChild(clonedElement);
    document.body.appendChild(hiddenContainer);
    clonedElement.style.width = `${dimensions.width}px`;
    clonedElement.style.height = `${dimensions.height}px`;

    try {
      const patchedCanvas = await getPatchedCanvasForHtml2Canvas({
        width: dimensions.width,
        height: dimensions.height,
        scale: 4,
      });
      if (!patchedCanvas) return;

      const canvas = await html2canvas(hiddenContainer, {
        canvas: patchedCanvas.canvas,
        scale: 4,
        useCORS: true,
        backgroundColor: null,
        width: dimensions.width,
        height: dimensions.height,
        logging: false,
        removeContainer: true,
        allowTaint: true,
      });

      const filename = `inspotype-industry-${industry["label"]}-title-${title.font["readable-font-family"]}-body-${body.font["readable-font-family"]}-color-${colorScheme["name"]}.png`;

      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = filename;
      link.click();

      notifications.show({
        title: "Downloaded moodboard image",
        message: "Check your downloads folder",
        color: "black",
        icon: <IconCheck size={14} />,
      });
    } finally {
      document.body.removeChild(hiddenContainer);
    }
  }

  async function handleAddToNewFolder() {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "add_to_folder",
      })
    ) {
      return;
    }

    const totalMoodboards = folders.reduce(
      (acc, folder) => acc + folder.moodboards.length,
      0,
    );

    if (!isPro && totalMoodboards >= 3) {
      openModalLifetimeIfNeeded({
        user,
        isPro,
        source: LifetimeModalTrigger.ADD_TO_NEW_FOLDER,
      });
      return;
    }

    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "create_new_folder",
      })
    ) {
      return;
    }

    openModalSaved({
      step: "createNewFolder",
      shareUrl: shareUrl,
    });
  }

  async function handleToggleInFolder(
    folder: SavedFolder,
    currentMoodboardUrl: string,
  ) {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "add_to_folder",
      })
    ) {
      return;
    }

    const isInFolder = folder.moodboards.includes(currentMoodboardUrl);

    const totalMoodboards = folders.reduce(
      (acc, folder) => acc + folder.moodboards.length,
      0,
    );

    if (!isPro && totalMoodboards >= 3 && !isInFolder) {
      openModalLifetimeIfNeeded({
        user,
        isPro,
        source: LifetimeModalTrigger.ADD_TO_FOLDER,
      });
      return;
    }

    if (isInFolder) {
      await updateMoodboards(
        folder.id,
        folder.moodboards.filter((m) => m !== currentMoodboardUrl),
      );
    } else {
      // Add the new moodboard to the beginning of the array
      await updateMoodboards(folder.id, [
        currentMoodboardUrl,
        ...folder.moodboards,
      ]);
    }

    await refetchFolders();
  }

  return (
    <Horizontal gap={"xxs"}>
      <Menu
        shadow="md"
        width={200}
        classNames={{
          item: style.addToFolderMenuItem,
        }}
        styles={{
          dropdown: {
            backgroundColor: "var(--mantine-color-gray-9)",
            borderColor: "var(--mantine-color-gray-6)",
            borderWidth: "2px",
            zIndex: 100,
          },
        }}
      >
        <Menu.Target>
          <Button
            bg={"black"}
            c={"white"}
            px={"sm"}
            py={"md"}
            h={44}
            radius={"xs"}
            styles={{
              label: {
                height: "44px",
              },
            }}
          >
            <Horizontal gap={"0"}>
              {addedInFolders.length > 0 && <IconBookmarkFilled size={24} />}
              {addedInFolders.length === 0 && <IconBookmark size={24} />}
              <Text ml={"xs"} variant="paragraph-md">
                Add to folder
              </Text>

              {addedInFolders.length > 0 && (
                <Text
                  ml={"xs"}
                  variant="label-sm"
                  bg={"gray.6"}
                  p={"xxs"}
                  style={{
                    borderRadius: "var(--mantine-radius-xxs)",
                  }}
                >
                  {addedInFolders.length}
                </Text>
              )}

              <IconChevronDown
                size={20}
                style={{ marginLeft: "var(--mantine-spacing-sm)" }}
              />
            </Horizontal>
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {!folders.length && (
            <Horizontal gap={"xs"} p={"lg"} center>
              <Text variant="paragraph-sm" c={"gray.4"}>
                No folders yet
              </Text>
            </Horizontal>
          )}

          {folders.length > 0 && (
            <>
              <Menu.Label>Folders</Menu.Label>
              {folders.map((folder) => {
                const isInFolder = addedInFolders.some(
                  (f) => f.id === folder.id,
                );
                return (
                  <Menu.Item
                    key={folder.id}
                    onClick={() => {
                      handleToggleInFolder(folder, shareUrl);
                    }}
                  >
                    <Horizontal gap={"xs"} h={"20px"} align="center">
                      {isInFolder && (
                        <IconCheck
                          color="white"
                          size={16}
                          style={{ marginRight: 4, flexShrink: 0 }}
                        />
                      )}
                      <Text
                        variant="paragraph-sm"
                        c={isInFolder ? "white" : "gray.4"}
                        flex={1}
                        style={{ marginLeft: isInFolder ? 0 : 20 }}
                      >
                        {folder.name}
                      </Text>
                      <Text variant="paragraph-sm" c={"gray.4"}>
                        {folder.moodboards.length}
                      </Text>
                      {isInFolder && (
                        <ActionIcon
                          mr={-4}
                          className={style.removeIcon}
                          size={18}
                          radius={"xxs"}
                          variant="transparent"
                        >
                          <IconX size={18} color="gray" />
                        </ActionIcon>
                      )}
                    </Horizontal>
                  </Menu.Item>
                );
              })}
            </>
          )}

          <UnstyledButton onClick={handleAddToNewFolder} w={"100%"}>
            <Horizontal
              gap={"xs"}
              px={"sm"}
              py={"sm"}
              bg={"gray.8"}
              style={{
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
              }}
            >
              <IconCirclePlus size={18} color="gray" />
              <Text variant="paragraph-sm" c={"gray.4"}>
                Add to new folder
              </Text>
            </Horizontal>
          </UnstyledButton>
        </Menu.Dropdown>
      </Menu>

      <ShareButton />

      <InspoKeyboardShortcutTooltip
        label="Download moodboard snapshot"
        shortcut={""}
        position="bottom"
      >
        <InspoActionIcon
          theme="dark"
          icon={<IconPhotoDown size={24} />}
          onClick={handleDownloadImage}
        />
      </InspoKeyboardShortcutTooltip>
    </Horizontal>
  );
};

function ShareSectionMobile({ onClick }: { onClick: () => void }) {
  return (
    <InspoActionIcon
      icon={<IconShare3 />}
      theme="light"
      radius={"xl"}
      onClick={onClick}
    />
  );
}

export function ShareButton() {
  const { user } = useUser();
  const { copy } = useClipboard();
  const { setIndustry } = useIndustry();
  const { setCardsState, setVisibleCardsKeys } = useCardsState();
  const { title, body, fontPairs, setFontPairIndex } = useFonts();
  const {
    customColorSchemeIsActive,
    setColorSchemeIndex,
    setOriginColorScheme,
    setCustomColorScheme,
    setCustomColorSchemeIsActive,
    setColorAtSlug,
  } = useColorPalette();

  const shareUrl = useShareUrl();

  // const ogCardParams = new URLSearchParams();
  // ogCardParams.append("fonts", fontsParam ?? "");
  // ogCardParams.append("palette", paletteParam ?? "");

  // const url = `${window.location.origin}/api/s/og?${ogCardParams.toString()}`;
  // const url = `${window.location.origin}/assets/card.png`;

  useEffect(function onlyForTracking() {
    if (hasShareParams()) {
      track({
        event: "share",
        properties: {
          action: "open",
          url: searchParams.toString(),
        },
      });
      return;
    }

    if (hasOpenNewsletterModalParam()) {
      track({
        event: "open_newsletter_modal",
        properties: {
          properties: { source: "direct_link" },
        },
      });
      openModalUpdates({ isMobile: false });
      removeUrlParam("newsletter");
    }
  }, []);

  // read query params
  // https://nextjs.org/docs/app/api-reference/functions/use-search-params#returns
  let searchParams = new URLSearchParams(document.location.search);
  const hasFontsParam = useState(searchParams.has("fonts"))[0];
  const fontsParam = useState(searchParams.get("fonts"))[0];
  const hasPaletteParam = useState(searchParams.has("palette"))[0];
  const paletteParam = useState(searchParams.get("palette"))[0];
  const hasIndustryParam = useState(searchParams.has("industry"))[0];
  const industryParam = useState(searchParams.get("industry"))[0];
  const hasCardsStateParam = useState(searchParams.has("cardsState"))[0];
  const cardsStateParam = useState(searchParams.get("cardsState"))[0];
  const hasVisibleCardsKeysParam = useState(
    searchParams.has("visibleCardsKeys"),
  )[0];
  const visibleCardsKeysParam = useState(
    searchParams.get("visibleCardsKeys"),
  )[0];

  const hasTitleIsLocalParam = useState(searchParams.has("titleIsLocal"))[0];
  const titleIsLocalParam = useState(searchParams.get("titleIsLocal"))[0];
  const hasTitleIsLockedAtParam = useState(
    searchParams.has("titleIsLockedAt"),
  )[0];
  const titleIsLockedAtParam = useState(searchParams.get("titleIsLockedAt"))[0];
  const hasTitleIsLockedAtFontParam = useState(
    searchParams.has("titleIsLockedAtFont"),
  )[0];
  const titleIsLockedAtFontParam = useState(
    searchParams.get("titleIsLockedAtFont"),
  )[0];
  const hasBodyIsLocalParam = useState(searchParams.has("bodyIsLocal"))[0];
  const bodyIsLocalParam = useState(searchParams.get("bodyIsLocal"))[0];
  const hasBodyIsLockedAtParam = useState(
    searchParams.has("bodyIsLockedAt"),
  )[0];
  const bodyIsLockedAtParam = useState(searchParams.get("bodyIsLockedAt"))[0];
  const hasBodyIsLockedAtFontParam = useState(
    searchParams.has("bodyIsLockedAtFont"),
  )[0];
  const bodyIsLockedAtFontParam = useState(
    searchParams.get("bodyIsLockedAtFont"),
  )[0];
  const hasColorIsLockedAtSlugParam = useState(
    searchParams.has("colorIsLockedAtSlug"),
  )[0];
  const colorIsLockedAtSlugParam = useState(
    searchParams.get("colorIsLockedAtSlug"),
  )[0];
  const hasCustomColorSchemeParam = useState(
    searchParams.has("customColorScheme"),
  )[0];
  const customColorSchemeParam = useState(
    searchParams.get("customColorScheme"),
  )[0];
  const hasOriginColorSchemeParam = useState(
    searchParams.has("originColorScheme"),
  )[0];
  const originColorSchemeParam = useState(
    searchParams.get("originColorScheme"),
  )[0];
  const hasCustomColorSchemeIsActiveParam = useState(
    searchParams.has("customColorSchemeIsActive"),
  )[0];
  const customColorSchemeIsActiveParam = useState(
    searchParams.get("customColorSchemeIsActive"),
  )[0];

  function removeUrlParam(param: string) {
    const url = new URL(window.location.href);
    url.searchParams.delete(param);
    window.history.replaceState({}, "", url.toString());
  }

  // should remove fonts param from url after it's been parsed by the title and body useEffects
  const [shouldRemoveFontsParam, setShouldRemoveFontsParam] = useState(false);

  useEffect(() => {
    if (!hasPaletteParam || !paletteParam) return;
    const colorScheme = colorSchemes.find((c) => c.slug === paletteParam);
    if (colorScheme !== undefined) {
      const colorSchemeIndex = colorSchemes.indexOf(colorScheme);
      setColorSchemeIndex({
        index: colorSchemeIndex,
        shouldSaveHistory: false,
      });
      setCustomColorSchemeIsActive({
        isActive: false,
        shouldSaveHistory: false,
      });
    }
    removeUrlParam("palette");
  }, [paletteParam, hasPaletteParam]);

  useEffect(() => {
    if (!hasIndustryParam || !industryParam) return;
    const industry = industries.find((i) => i.slug === industryParam);
    if (industry !== undefined) {
      setIndustry(industry);
    }
    removeUrlParam("industry");
  }, [industryParam, hasIndustryParam, industries]);

  useEffect(() => {
    let shouldDefaultTitleToFontPair = hasFontsParam && !!fontsParam;
    if (hasTitleIsLockedAtParam && !!titleIsLockedAtParam) {
      // keeping this for legacy reasons, it's not being put in the params anymore as of 27/12/2024
      const titleIsLockedAt = parseInt(titleIsLockedAtParam);
      if (titleIsLockedAt >= 0 && titleIsLockedAt < fontPairs.length) {
        title.setFontAt({
          readableFontFamily:
            fontPairs[titleIsLockedAt].title["readable-font-family"],
          shouldLock: false,
          shouldSaveHistory: false,
        });
      }
      removeUrlParam("titleIsLockedAt");
      shouldDefaultTitleToFontPair = false;
    }

    if (hasTitleIsLockedAtFontParam && !!titleIsLockedAtFontParam) {
      title.setFontAt({
        readableFontFamily: titleIsLockedAtFontParam,
        shouldLock: false,
        shouldSaveHistory: false,
      });
      removeUrlParam("titleIsLockedAtFont");
      shouldDefaultTitleToFontPair = false;
    }

    if (shouldDefaultTitleToFontPair) {
      const titleFont = fontPairs.find((pair) => pair.slug === fontsParam)
        ?.title["readable-font-family"];
      if (titleFont) {
        title.setFontAt({
          readableFontFamily: titleFont,
          shouldLock: false,
          shouldSaveHistory: false,
        });
      }
    }

    if (hasTitleIsLocalParam && !!titleIsLocalParam) {
      title.setIsLocal(titleIsLocalParam === "true");
      removeUrlParam("titleIsLocal");
    }

    removeUrlParam("fonts");
  }, [
    titleIsLockedAtParam,
    hasTitleIsLockedAtParam,
    hasTitleIsLockedAtFontParam,
    titleIsLockedAtFontParam,
    hasTitleIsLocalParam,
    titleIsLocalParam,
    fontsParam,
    hasFontsParam,
    shouldRemoveFontsParam,
  ]);

  useEffect(() => {
    let shouldDefaultBodyToFontPair = hasFontsParam && !!fontsParam;
    if (hasBodyIsLockedAtParam && !!bodyIsLockedAtParam) {
      // keeping this for legacy reasons, it's not being put in the params anymore as of 27/12/2024
      const bodyIsLockedAt = parseInt(bodyIsLockedAtParam);
      if (bodyIsLockedAt >= 0 && bodyIsLockedAt < fontPairs.length) {
        body.setFontAt({
          readableFontFamily:
            fontPairs[bodyIsLockedAt].body["readable-font-family"],
          shouldLock: false,
          shouldSaveHistory: false,
        });
      }
      removeUrlParam("bodyIsLockedAt");
      shouldDefaultBodyToFontPair = false;
    }

    if (hasBodyIsLockedAtFontParam && !!bodyIsLockedAtFontParam) {
      body.setFontAt({
        readableFontFamily: bodyIsLockedAtFontParam,
        shouldLock: false,
        shouldSaveHistory: false,
      });
      removeUrlParam("bodyIsLockedAtFont");
      shouldDefaultBodyToFontPair = false;
    }

    if (shouldDefaultBodyToFontPair) {
      const bodyFont = fontPairs.find((pair) => pair.slug === fontsParam)?.body[
        "readable-font-family"
      ];
      if (bodyFont) {
        body.setFontAt({
          readableFontFamily: bodyFont,
          shouldLock: false,
          shouldSaveHistory: false,
        });
      }
    }

    if (hasBodyIsLocalParam && !!bodyIsLocalParam) {
      body.setIsLocal(bodyIsLocalParam === "true");
      removeUrlParam("bodyIsLocal");
    }

    removeUrlParam("fonts");
  }, [
    bodyIsLockedAtFontParam,
    bodyIsLockedAtParam,
    hasBodyIsLockedAtFontParam,
    hasBodyIsLockedAtParam,
    hasBodyIsLocalParam,
    bodyIsLocalParam,
    fontsParam,
    hasFontsParam,
    shouldRemoveFontsParam,
  ]);

  useEffect(() => {
    if (hasColorIsLockedAtSlugParam && colorIsLockedAtSlugParam !== null) {
      const colorIsLockedAtSlug = colorSchemes.find(
        (c) => c.slug === colorIsLockedAtSlugParam,
      )?.slug;
      setColorAtSlug({
        slug: colorIsLockedAtSlug,
        shouldLock: true,
        shouldSaveHistory: false,
      });
      removeUrlParam("colorIsLockedAtSlug");
    }
  }, [colorIsLockedAtSlugParam, hasColorIsLockedAtSlugParam]);

  useEffect(() => {
    if (
      hasCustomColorSchemeParam &&
      hasOriginColorSchemeParam &&
      customColorSchemeParam &&
      originColorSchemeParam
    ) {
      try {
        const parsedCustomScheme = JSON.parse(customColorSchemeParam);
        const parsedOriginScheme = JSON.parse(originColorSchemeParam);
        setCustomColorScheme({
          scheme: parsedCustomScheme,
          shouldSaveHistory: false,
        });
        setOriginColorScheme(parsedOriginScheme);
        setCustomColorSchemeIsActive({
          isActive:
            hasCustomColorSchemeIsActiveParam &&
            customColorSchemeIsActiveParam === "true",
          shouldSaveHistory: false,
        });
      } catch (e) {
        console.error("Error parsing custom color scheme params:", e);
      }
    }

    removeUrlParam("customColorScheme");
    removeUrlParam("originColorScheme");
    removeUrlParam("customColorSchemeIsActive");
  }, [
    customColorSchemeParam,
    hasCustomColorSchemeParam,
    hasOriginColorSchemeParam,
    originColorSchemeParam,
    customColorSchemeIsActiveParam,
  ]);

  useEffect(() => {
    if (!hasCardsStateParam || !cardsStateParam) return;
    setCardsState(JSON.parse(cardsStateParam));
    removeUrlParam("cardsState");
  }, [cardsStateParam, hasCardsStateParam]);

  useEffect(() => {
    if (!hasVisibleCardsKeysParam || !visibleCardsKeysParam) return;
    setVisibleCardsKeys(JSON.parse(visibleCardsKeysParam));
    removeUrlParam("visibleCardsKeys");
  }, [visibleCardsKeysParam, hasVisibleCardsKeysParam]);

  function onClickShare() {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "share",
      })
    ) {
      return;
    }

    if (!shareUrl) return;

    copy(shareUrl);

    notifications.show({
      id: "share",
      title: "Copied moodboard link to clipboard",
      message: (() => {
        if (title.isLocal && body.isLocal) {
          return "The title and body are locked to local fonts and will not be shared in your URL";
        } else if (title.isLocal) {
          return "The title is locked to a local font and will not be shared in your URL";
        } else if (body.isLocal) {
          return "The body font is locked to a local font and will not be shared in your URL";
        }
      })(),
      color: "dark",
      icon: <IconCheck size={14} />,
    });

    track({
      event: "share",
      properties: {
        action: "create",
        url: new URL(shareUrl).searchParams.toString(),
      },
    });
  }

  return (
    <InspoKeyboardShortcutTooltip
      label="Get link to moodboard"
      shortcut={""}
      position="bottom"
    >
      <InspoActionIcon
        theme="dark"
        icon={<IconShare size={24} />}
        onClick={onClickShare}
      />
    </InspoKeyboardShortcutTooltip>
  );
}

function useShareUrl() {
  const { user } = useUser();
  const { industry } = useIndustry();
  const {
    currentColorScheme,
    customColorScheme,
    originColorScheme,
    customColorSchemeIsActive,
    colorIsLockedAtSlug,
  } = useColorPalette();
  const { currentFontPair, title, body } = useFonts();
  const bentoCards = useBentoCards();
  const { cardsState, visibleCardsKeys } = useCardsState();

  const shareUrl = useMemo(() => {
    const params = new URLSearchParams();
    params.append("fonts", currentFontPair.slug);
    params.append("palette", currentColorScheme.slug);
    params.append("industry", industry.slug);

    currentFontPair.title !== title.font["readable-font-family"] &&
      params.append(
        "titleIsLockedAtFont",
        title.font["readable-font-family"].toString(),
      );
    title.isLocal && params.append("titleIsLocal", "true");

    currentFontPair.body !== body.font["readable-font-family"] &&
      params.append("bodyIsLockedAtFont", body.font["readable-font-family"]);
    body.isLocal && params.append("bodyIsLocal", "true");

    // Only add color scheme related params if not using a custom scheme
    if (!customColorSchemeIsActive) {
      colorIsLockedAtSlug !== undefined &&
        params.append("colorIsLockedAtSlug", colorIsLockedAtSlug);
    }

    // Add custom color scheme params if active
    if (customColorSchemeIsActive && customColorScheme && originColorScheme) {
      params.append("customColorScheme", JSON.stringify(customColorScheme));
      params.append("originColorScheme", JSON.stringify(originColorScheme));
      params.append("customColorSchemeIsActive", "true");
    }

    const industryCardsState = (bentoCards || [])
      .map((card) => card.props.children.key)
      .filter((key) => key !== undefined)
      .reduce((acc, key) => {
        if (key in cardsState) {
          acc[key] = cardsState[key];
        }
        return acc;
      }, {});
    params.append("cardsState", JSON.stringify(industryCardsState));
    params.append("visibleCardsKeys", JSON.stringify(visibleCardsKeys));

    const urlToShare = `${window.location.origin}?${params.toString()}`;

    return urlToShare;
  }, [
    user,
    industry,
    currentFontPair,
    title,
    body,
    currentColorScheme,
    customColorScheme,
    originColorScheme,
    cardsState,
    colorIsLockedAtSlug,
    customColorSchemeIsActive,
    visibleCardsKeys,
  ]);

  return shareUrl;
}

