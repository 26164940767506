import { Text } from "@mantine/core";
import { IconArrowBackUp, IconArrowForwardUp } from "@tabler/icons-react";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { debounce, InspoEvent, LifetimeModalTrigger, track } from "../utils";
import { InspoActionIcon } from "./ui/InspoActionIcon";
import { InspoKeyboardShortcutTooltip } from "./ui/InspoKeyboardShortcutTooltip";
import { openModalLoginIfNeeded } from "./ModalLogin";
import { useUser } from "../contexts/UserContext";
import { useHotkeys } from "@mantine/hooks";
import { useCardsState } from "../contexts/CardsStateContext";
import { useFonts } from "../contexts/FontsContext";
import { useColorPalette } from "../contexts/ColorSchemeContext";
import { useEffect, useState } from "react";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";

export const HistorySection = ({}: {}) => {
  const { user, isPro } = useUser();
  const { cardsState, setCardsState, visibleCardsKeys, setVisibleCardsKeys } =
    useCardsState();
  const {
    colorSchemeIndex,
    setColorSchemeIndex,
    colorIsLockedAtSlug,
    setColorAtSlug,
    customColorSchemeIsActive,
    setCustomColorSchemeIsActive,
    customColorScheme,
    setCustomColorScheme,
  } = useColorPalette();
  const { title, body } = useFonts();

  const [history, setHistory] = useState<
    Array<{
      colorSchemeIndex: number;
      cardsState: Record<string, BentoCardChildrenProps>;
      visibleCardsKeys: string[];
      // Font locking state
      title: {
        readableFontFamily: string | undefined;
        isLocal: boolean | undefined;
      };
      body: {
        readableFontFamily: string | undefined;
        isLocal: boolean | undefined;
      };
      // Color scheme state
      colorIsLockedAtSlug: string | undefined;
      customColorSchemeIsActive: boolean;
      customColorScheme: any;
    }>
  >([]);

  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const [shouldUpdateHistory, setShouldUpdateHistory] = useState(false);

  useEffect(() => {
    function handleSetHistoryCheckpoint() {
      setShouldUpdateHistory(true);
    }

    document.addEventListener(
      InspoEvent.SET_HISTORY_CHECKPOINT,
      handleSetHistoryCheckpoint,
    );

    return () => {
      document.removeEventListener(
        InspoEvent.SET_HISTORY_CHECKPOINT,
        handleSetHistoryCheckpoint,
      );
    };
  }, []);

  useEffect(() => {
    const debouncedUpdateHistory = debounce(() => {
      const currentHistoryStep = history[currentHistoryIndex];
      const isFirstStep = currentHistoryIndex === 0 && history.length === 0;

      if (!isFirstStep) {
        if (currentHistoryStep === undefined) {
          return;
        }

        const hasChanges =
          history[currentHistoryIndex].colorSchemeIndex !== colorSchemeIndex ||
          history[currentHistoryIndex].visibleCardsKeys !== visibleCardsKeys ||
          JSON.stringify(history[currentHistoryIndex].cardsState) !==
            JSON.stringify(cardsState) ||
          // Font changes
          history[currentHistoryIndex].title.readableFontFamily !==
            title.font["readable-font-family"] ||
          history[currentHistoryIndex].title.isLocal !== title.isLocal ||
          history[currentHistoryIndex].body.readableFontFamily !==
            body.font["readable-font-family"] ||
          history[currentHistoryIndex].body.isLocal !== body.isLocal ||
          // Color scheme changes
          history[currentHistoryIndex].colorIsLockedAtSlug !==
            colorIsLockedAtSlug ||
          history[currentHistoryIndex].customColorSchemeIsActive !==
            customColorSchemeIsActive ||
          JSON.stringify(history[currentHistoryIndex].customColorScheme) !==
            JSON.stringify(customColorScheme);

        if (!hasChanges) {
          return;
        }
      }

      setHistory((prev) => {
        const newHistory = [
          ...prev.slice(0, currentHistoryIndex + 1),
          {
            colorSchemeIndex,
            cardsState,
            visibleCardsKeys,
            title: {
              readableFontFamily: title.font["readable-font-family"],
              isLocal: title.isLocal,
            },
            body: {
              readableFontFamily: body.font["readable-font-family"],
              isLocal: body.isLocal,
            },
            colorIsLockedAtSlug,
            customColorSchemeIsActive,
            customColorScheme,
          },
        ];
        return newHistory;
      });

      if (!isFirstStep) {
        setCurrentHistoryIndex((prev) => prev + 1);
      }

      setShouldUpdateHistory(false);
    }, 200);

    if (shouldUpdateHistory) {
      debouncedUpdateHistory();
    }

    return () => {
      debouncedUpdateHistory.cancel();
    };
  }, [
    shouldUpdateHistory,
    currentHistoryIndex,
    history,
    colorSchemeIndex,
    cardsState,
    visibleCardsKeys,
    title.font,
    title.isLocal,
    body.font,
    body.isLocal,
    colorIsLockedAtSlug,
    customColorSchemeIsActive,
    customColorScheme,
  ]);

  useHotkeys([
    [
      "alt+z",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.z",
          })
        ) {
          return;
        }

        if (
          openModalLifetimeIfNeeded({
            user,
            isPro,
            source: LifetimeModalTrigger.HISTORY,
          })
        ) {
          return;
        }

        track({
          event: "change_history",
          properties: { source: "keyboard", action: "undo" },
        });
        undo();
      },
    ],
    [
      "alt+shift+z",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.shiftz",
          })
        ) {
          return;
        }

        if (
          openModalLifetimeIfNeeded({
            user,
            isPro,
            source: LifetimeModalTrigger.HISTORY,
          })
        ) {
          return;
        }

        track({
          event: "change_history",
          properties: { source: "keyboard", action: "redo" },
        });
        redo();
      },
    ],
  ]);

  function undo() {
    if (currentHistoryIndex <= 0) return;
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "history.undo",
      })
    ) {
      return;
    }

    document.dispatchEvent(new CustomEvent(InspoEvent.RESET_CUSTOM_TEXT));

    const prevState = history[currentHistoryIndex - 1];
    setColorSchemeIndex({
      index: prevState.colorSchemeIndex,
      shouldSaveHistory: false,
    });
    setVisibleCardsKeys(prevState.visibleCardsKeys);
    setCardsState(prevState.cardsState);
    // Restore font locks
    prevState.title.readableFontFamily &&
      title.setFontAt({
        readableFontFamily: prevState.title.readableFontFamily,
        isLocal: prevState.title.isLocal,
        shouldLock: false,
        shouldSaveHistory: false,
      });
    prevState.body.readableFontFamily &&
      body.setFontAt({
        readableFontFamily: prevState.body.readableFontFamily,
        isLocal: prevState.body.isLocal,
        shouldLock: false,
        shouldSaveHistory: false,
      });
    // Restore color scheme
    if (prevState.colorIsLockedAtSlug !== undefined) {
      setColorAtSlug({
        slug: prevState.colorIsLockedAtSlug,
        shouldLock: true,
        shouldSaveHistory: false,
      });
    }
    setCustomColorSchemeIsActive({
      isActive: prevState.customColorSchemeIsActive,
      shouldSaveHistory: false,
    });
    setCustomColorScheme({
      scheme: prevState.customColorScheme,
      shouldSaveHistory: false,
    });
    setCurrentHistoryIndex((prev) => prev - 1);
  }

  function redo() {
    if (currentHistoryIndex >= history.length - 1) return;
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "history.redo",
      })
    ) {
      return;
    }

    document.dispatchEvent(new CustomEvent(InspoEvent.RESET_CUSTOM_TEXT));

    const nextState = history[currentHistoryIndex + 1];
    setColorSchemeIndex({
      index: nextState.colorSchemeIndex,
      shouldSaveHistory: false,
    });
    setVisibleCardsKeys(nextState.visibleCardsKeys);
    setCardsState(nextState.cardsState);
    // Restore font locks
    nextState.title.readableFontFamily &&
      title.setFontAt({
        readableFontFamily: nextState.title.readableFontFamily,
        isLocal: nextState.title.isLocal,
        shouldLock: false,
        shouldSaveHistory: false,
      });
    nextState.body.readableFontFamily &&
      body.setFontAt({
        readableFontFamily: nextState.body.readableFontFamily,
        isLocal: nextState.body.isLocal,
        shouldLock: false,
        shouldSaveHistory: false,
      });
    // Restore color scheme
    if (nextState.colorIsLockedAtSlug !== undefined) {
      setColorAtSlug({
        slug: nextState.colorIsLockedAtSlug,
        shouldLock: true,
        shouldSaveHistory: false,
      });
    }
    setCustomColorSchemeIsActive({
      isActive: nextState.customColorSchemeIsActive,
      shouldSaveHistory: false,
    });
    setCustomColorScheme({
      scheme: nextState.customColorScheme,
      shouldSaveHistory: false,
    });
    setCurrentHistoryIndex((prev) => prev + 1);
  }

  return (
    <Horizontal gap={"sm"} h={44} center noWrap>
      <Text variant="label-lg">HISTORY</Text>

      <Horizontal
        gap={"xxxs"}
        noWrap
        style={{
          borderRadius: "var(--mantine-radius-xs)",
          overflow: "hidden",
        }}
      >
        <InspoKeyboardShortcutTooltip
          label={"Previous change"}
          shortcut={"alt + z"}
        >
          <InspoActionIcon
            theme="light"
            disabled={currentHistoryIndex <= 0}
            icon={<IconArrowBackUp />}
            radius={0}
            onClick={() => {
              track({
                event: "change_history",
                properties: { source: "button", action: "undo" },
              });
              undo();
            }}
          />
        </InspoKeyboardShortcutTooltip>

        <InspoKeyboardShortcutTooltip
          label={"Next change"}
          shortcut={"alt + shift + z"}
        >
          <InspoActionIcon
            theme="light"
            disabled={currentHistoryIndex >= history.length - 1}
            icon={<IconArrowForwardUp />}
            radius={0}
            onClick={() => {
              track({
                event: "change_history",
                properties: { source: "button", action: "redo" },
              });
              redo();
            }}
          />
        </InspoKeyboardShortcutTooltip>
      </Horizontal>
    </Horizontal>
  );
};
