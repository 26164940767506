import { Anchor, Text } from "@mantine/core";

export function InspoButtonUnderline({
  onClick = () => {},
  children,
  style,
}: {
  onClick: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <Anchor component="button" onClick={(e) => onClick?.()} style={style}>
      <Text variant={"paragraph-lg"}>{children}</Text>
    </Anchor>
  );
}
