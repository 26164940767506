import { AspectRatio, Image, Space, Text, Title } from "@mantine/core";
import type { InspoTypeCardProps } from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import { InspoCardTag } from "../ui/InspoCardTag";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { InspoCardText, InspoCardTitle } from "../ui/InspoCardContent";

type BlogArticleWithImageLeftAndTagTopContentProps = {
  tag: string;
  title: string;
  subtitle: string;
  imgs: string[];
  alt: string;
};

export const blogArticleWithImageLeftAndTagTopContent = {
  editorial: {
    tag: "Trending",
    title: "Solar Eclipse",
    subtitle:
      "The comet might be bright enough to see with the unaided eye during the eclipse, but only if it erupts at the right time.",
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_square",
    }),
    alt: "Product Image",
  },
} as const satisfies Record<
  string,
  BlogArticleWithImageLeftAndTagTopContentProps
>;

export default function BlogArticleWithImageLeftAndTagTop({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<BlogArticleWithImageLeftAndTagTopContentProps> &
  BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Vertical fullW fullH center bg={colorScheme.background} px="md" {...props}>
      <Horizontal noWrap>
        <AspectRatio ratio={1 / 1} mah={200} maw={200}>
          <Image
            src={uploadedImagePath ?? content.imgs[imgIndex]}
            alt={content.alt}
            fit="cover"
            radius={"xxs"}
          />
        </AspectRatio>
        <Vertical gap={0}>
          <InspoCardTag label={content.tag} colorScheme={colorScheme} />

          <Space h={"md"} />

          <InspoCardTitle
            variant="card-headline-sm"
            c={colorScheme.textdark}
            textId="title"
            {...props}
          >
            {content.title}
          </InspoCardTitle>

          <Space h={"xs"} />

          <InspoCardText
            variant="card-paragraph-lg"
            c={colorScheme.textdark}
            textId="subtitle"
            {...props}
          >
            {content.subtitle}
          </InspoCardText>
        </Vertical>
      </Horizontal>
    </Vertical>
  );
}
