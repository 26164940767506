import { Chip, rem, type ChipProps } from "@mantine/core";
import style from "./../../../../styles/CustomComponents.module.scss";
import typo from "./../../../../styles/Typography.module.scss";
import { IconX } from "@tabler/icons-react";

export function InspoChip({
  children,
  ...props
}: { children: React.ReactNode } & ChipProps) {
  return (
    <Chip
      icon={<IconX size={12} />}
      classNames={{
        label: [typo.labelSm, style.inspoChipLabel].join(" "),
      }}
      styles={{
        root: {
          position: "relative",
        },
        label: {
          backgroundColor: "transparent",
          color: "white",
          border: "1px solid rgba(120, 113, 108, 1)",
          padding: "12px 12px",
        },
        iconWrapper: {
          position: "absolute",
          left: "8px",
        },
      }}
      {...props}
    >
      {children}
    </Chip>
  );
}
