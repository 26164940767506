import { IconArrowsShuffle, IconArrowsShuffle2 } from "@tabler/icons-react";
import { isMobile } from "react-device-detect";
import { InspoEvent, LifetimeModalTrigger, track } from "../utils";
import style from "./../../../styles/CustomComponents.module.scss";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { InspoActionIcon } from "./ui/InspoActionIcon";
import { InspoButton } from "./ui/InspoButton";
import { InspoKeyboardShortcutTooltip } from "./ui/InspoKeyboardShortcutTooltip";
import { useFonts } from "../contexts/FontsContext";
import { useColorPalette } from "../contexts/ColorSchemeContext";
import { useHotkeys } from "@mantine/hooks";
import { openModalLoginIfNeeded } from "./ModalLogin";
import { useUser } from "../contexts/UserContext";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";

export const RandomSection = ({
  version,
}: {
  version: "desktop" | "mobile";
}) => {
  const { user, isPro } = useUser();
  const { handleFontPairChange } = useFonts();
  const { handleColorSchemeChange } = useColorPalette();

  useHotkeys([
    [
      "space",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.space",
          })
        ) {
          return;
        }

        if (
          openModalLifetimeIfNeeded({
            user,
            isPro,
            source: LifetimeModalTrigger.RANDOM,
          })
        ) {
          return;
        }

        track({
          event: "randomize",
          properties: { source: "keyboard" },
        });
        
        handleColorSchemeChange("random");
        handleFontPairChange("random");
        document.dispatchEvent(new CustomEvent(InspoEvent.RANDOM));
      },
    ],
  ]);

  function handleClick() {
    track({
      event: "randomize",
      properties: { source: "button" },
    });
    handleColorSchemeChange("random");
    handleFontPairChange("random");
    document.dispatchEvent(new CustomEvent(InspoEvent.RANDOM));
  }

  if (version === "mobile") {
    return (
      <InspoButton
        flex={"1"}
        icon={<IconArrowsShuffle />}
        iconPos="right"
        theme="light"
        radius={"xl"}
        onClick={handleClick}
      >
        Random
      </InspoButton>
    );
  }

  return (
    <Horizontal
      classNames={{ root: style.bottomBarSectionRoot }}
      px={"xs"}
      py={"xs"}
      center
    >
      <InspoKeyboardShortcutTooltip label={"Randomize"} shortcut={"SPACEBAR"}>
        <InspoActionIcon
          theme="dark"
          h={44}
          w={44}
          disabled={isMobile}
          icon={<IconArrowsShuffle2 />}
          onClick={handleClick}
        />
      </InspoKeyboardShortcutTooltip>
    </Horizontal>
  );
};
