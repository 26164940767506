import { theme } from "../../../styles/mantine-theme";

export function setBodyVariables(body: BodyConfig) {
  document.documentElement.style.setProperty(
    `--card-body-font-family`,
    body?.["font-family"] ?? bodyDefault["font-family"] ?? null,
  );

  (
    [
      "body",
      "bodySemibold",
      "body2",
      "body2Stroke",
      "overline",
      "overlineStroke",
      "paragraph-xl",
      "paragraph-lg",
      "paragraph-md",
      "paragraph-sm",
      "label-lg",
      "label-md",
      "label-sm",
    ] as Array<BodyOrdersKey>
  ).forEach((order: BodyOrdersKey, i) => {
    document.documentElement.style.setProperty(
      `--card-body-font-size-${order}`,
      // @ts-ignore 7053
      body?.[order]?.["font-size"] ?? bodyDefault[order]?.["font-size"] ?? null,
    );

    document.documentElement.style.setProperty(
      `--card-body-line-height-${order}`,
      // @ts-ignore 7053
      body?.[order]?.["line-height"]?.toString() ??
        // @ts-ignore 7053
        bodyDefault[order]?.["line-height"]?.toString() ??
        null,
    );

    document.documentElement.style.setProperty(
      `--card-body-font-weight-${order}`,
      // @ts-ignore 7053
      body?.[order]?.["font-weight"]?.toString() ??
        // @ts-ignore 7053
        bodyDefault[order]?.["font-weight"]?.toString() ??
        null,
    );

    document.documentElement.style.setProperty(
      `--card-body-letter-spacing-${order}`,
      // @ts-ignore 7053
      body?.[order]?.["letter-spacing"]?.toString() ??
        // @ts-ignore 7053
        bodyDefault[order]?.["letter-spacing"]?.toString() ??
        null,
    );

    document.documentElement.style.setProperty(
      `--card-body-text-transform-${order}`,
      // @ts-ignore 7053
      body?.[order]?.["text-transform"]?.toString() ??
        // @ts-ignore 7053
        bodyDefault[order]?.["text-transform"]?.toString() ??
        null,
    );
  });
}

interface BodyOrderConfig {
  "font-size"?: string;
  "line-height"?: string;
  "font-weight"?: number;
  "letter-spacing"?: string;
  "text-transform"?: string;
}

interface BodyConfig {
  "readable-font-family"?: string;
  "font-family"?: string;
  "foundry-url"?: string;
  source?: "free" | "adobe" | "custom";
  foundry?: string;
  body?: BodyOrderConfig;
  bodySemibold?: BodyOrderConfig;
  body2?: BodyOrderConfig;
  body2Stroke?: BodyOrderConfig;
  overline?: BodyOrderConfig;
  overlineStroke?: BodyOrderConfig;
  "paragraph-xl"?: BodyOrderConfig;
  "paragraph-lg"?: BodyOrderConfig;
  "paragraph-md"?: BodyOrderConfig;
  "paragraph-sm"?: BodyOrderConfig;
  "label-lg"?: BodyOrderConfig;
  "label-md"?: BodyOrderConfig;
  "label-sm"?: BodyOrderConfig;
}

type BodyOrdersKey = Exclude<keyof BodyConfig, "font-family">;

const bodyDefault: BodyConfig = {
  "font-family": undefined,
  body: {
    "font-size": "var(--mantine-font-size-md)",
    "line-height": "28px",
    "font-weight": 400,
  },
  bodySemibold: {
    "font-size": "var(--mantine-font-size-md)",
    "line-height": "28px",
    "font-weight": 800,
  },
  body2: {
    "font-size": "var(--mantine-font-size-sm)",
    "line-height": "22.4px",
    "font-weight": 400,
  },
  body2Stroke: {
    "font-size": "var(--mantine-font-size-sm)",
    "line-height": "22.4px",
    "font-weight": 400,
  },
  overline: {
    "font-size": "var(--mantine-font-size-xs)",
    "line-height": "16.8px",
    "font-weight": 600,
    "letter-spacing": "1px",
  },
  overlineStroke: {
    "font-size": "var(--mantine-font-size-xs)",
    "line-height": "16.8px",
    "font-weight": 600,
    "letter-spacing": "1px",
  },
  "paragraph-xl": {
    "font-size": "18px",
    "line-height": "130%",
    "font-weight": 500,
  },
  "paragraph-lg": {
    "font-size": "16px",
    "line-height": "130%",
    "font-weight": 500,
  },
  "paragraph-md": {
    "font-size": "14px",
    "line-height": "130%",
    "font-weight": 500,
  },
  "paragraph-sm": {
    "font-size": "12px",
    "line-height": "130%",
    "font-weight": 500,
  },
  "label-lg": {
    "font-size": "12px",
    "line-height": "100%",
    "font-weight": 500,
    "text-transform": "uppercase",
  },
  "label-md": {
    "font-size": "10px",
    "line-height": "100%",
    "font-weight": 500,
    "text-transform": "uppercase",
  },
  "label-sm": {
    "font-size": "9px",
    "line-height": "100%",
    "font-weight": 500,
    "text-transform": "uppercase",
  },
};

export const bodyInter: BodyConfig = {
  "readable-font-family": "Inter",
  "font-family": "Inter",
  "foundry-url": "https://fonts.google.com/specimen/Inter",
  source: "free",
  foundry: "Google",
};

export const bodyIBM: BodyConfig = {
  "readable-font-family": "IBM Plex Sans",
  "font-family": "IBM Plex Sans",
  "foundry-url": "https://fonts.google.com/specimen/IBM+Plex+Sans",
  source: "free",
  foundry: "Google",
};

export const bodyRubik: BodyConfig = {
  "readable-font-family": "Rubik",
  "font-family": "Rubik",
  "foundry-url": "https://fonts.google.com/specimen/Rubik?query=rubik",
  source: "free",
  foundry: "Google",
};

export const bodyLora: BodyConfig = {
  "readable-font-family": "Lora",
  "font-family": "Lora",
  "foundry-url": "https://fonts.google.com/specimen/Lora",
  source: "free",
  foundry: "Google",
};

export const bodyOnest: BodyConfig = {
  "readable-font-family": "Onest",
  "font-family": "Onest",
  "foundry-url": "https://fonts.google.com/specimen/Onest",
  source: "free",
  foundry: "Google",
  "paragraph-xl": {
    "font-weight": 400,
  },

  "paragraph-lg": {
    "font-weight": 400,
  },

  "paragraph-md": {
    "font-weight": 400,
  },

  "paragraph-sm": {
    "font-weight": 400,
  },
  "label-lg": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "label-md": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "label-sm": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
};

export const bodyArchivo: BodyConfig = {
  "readable-font-family": "Archivo",
  "font-family": "Archivo",
  "foundry-url": "https://fonts.google.com/specimen/Archivo?query=archivo",
  source: "free",
  foundry: "Google",
};

export const bodySupreme: BodyConfig = {
  "readable-font-family": "Supreme",
  "font-family": "Supreme",
  "foundry-url": "https://www.fontshare.com/fonts/supreme",
  source: "free",
  foundry: "FontShare",
};

export const bodyPlusJakartaSans: BodyConfig = {
  "readable-font-family": "Plus Jakarta Sans",
  "font-family": "Plus Jakarta Sans",
  "foundry-url": "https://fonts.google.com/specimen/Plus+Jakarta+Sans",
  source: "free",
  foundry: "Google",
};

export const bodyRecia: BodyConfig = {
  "readable-font-family": "Recia",
  "font-family": "Recia",
  "foundry-url": "https://www.fontshare.com/fonts/recia",
  source: "free",
  foundry: "FontShare",
};

export const bodyBespoke: BodyConfig = {
  "readable-font-family": "Bespoke Serif",
  "font-family": "Bespoke Serif",
  "foundry-url": "https://www.fontshare.com/fonts/bespoke-serif",
  source: "free",
  foundry: "FontShare",
};

export const bodySatoshi: BodyConfig = {
  "readable-font-family": "Satoshi",
  "font-family": "Satoshi",
  "foundry-url": "https://www.fontshare.com/fonts/satoshi",
  source: "free",
  foundry: "FontShare",
};

export const bodyNeueHaasUnica: BodyConfig = {
  "readable-font-family": "Neue Haas Unica",
  "font-family": "neue-haas-unica",
  "foundry-url":
    "https://fonts.adobe.com/fonts/neue-haas-unica/?ref=inspotype.com",
  source: "adobe",
  foundry: "Monotype",
};

export const bodyArticulat: BodyConfig = {
  "readable-font-family": "Articulat CF",
  "font-family": "articulat-cf",
  "foundry-url":
    "https://fonts.adobe.com/fonts/articulat-cf/?ref=inspotype.com",
  source: "adobe",
  foundry: "Connary Fagen",
};

export const bodyEpilogue: BodyConfig = {
  "readable-font-family": "Epilogue",
  "font-family": "Epilogue",
  "foundry-url": "https://fonts.google.com/specimen/Epilogue",
  source: "free",
  foundry: "Google",
};

export const bodyProximaNova: BodyConfig = {
  "readable-font-family": "Proxima Nova",
  "font-family": "proxima-nova",
  "foundry-url":
    "https://fonts.adobe.com/fonts/proxima-nova/?ref=inspotype.com",
  source: "adobe",
  foundry: "Mark Simonson Studio",
};

export const bodyTradeGothicNext: BodyConfig = {
  "readable-font-family": "Trade Gothic Next",
  "font-family": "trade-gothic-next",
  "foundry-url":
    "https://fonts.adobe.com/fonts/trade-gothic-next/?ref=inspotype.com",
  source: "adobe",
  foundry: "Monotype",
};

export const bodyMaiola: BodyConfig = {
  "readable-font-family": "Maiola",
  "font-family": "maiola",
  "foundry-url": "https://fonts.adobe.com/fonts/maiola/?ref=inspotype.com",
  source: "adobe",
  foundry: "TypeTogether",
};

export const bodyBitter: BodyConfig = {
  "readable-font-family": "Bitter",
  "font-family": "Bitter",
  "foundry-url": "https://fonts.google.com/specimen/Bitter",
  source: "free",
  foundry: "Google",
};

export const bodyLibreFranklin: BodyConfig = {
  "readable-font-family": "Libre Franklin",
  "font-family": "Libre Franklin",
  "foundry-url": "https://fonts.google.com/specimen/Libre+Franklin",
  source: "free",
  foundry: "Google",
};

// export const bodySourceSerif: BodyConfig = {
//   "readable-font-family": "Source Serif",
//   "font-family": "Source Serif 4",
//   "foundry-url": "https://fonts.google.com/specimen/Source+Serif+4",
//   source: "free",
//   foundry: "Google",
// };

export const bodyTiroTamil: BodyConfig = {
  "readable-font-family": "Tiro Tamil",
  "font-family": "Tiro Tamil",
  "foundry-url": "https://fonts.google.com/specimen/Tiro+Tamil",
  source: "free",
  foundry: "Google",
};

export const bodyAdobeCaslon: BodyConfig = {
  "readable-font-family": "Adobe Caslon",
  "font-family": "adobe-caslon-pro",
  "foundry-url":
    "https://fonts.adobe.com/fonts/adobe-caslon/?ref=inspotype.com",
  source: "adobe",
  foundry: "Adobe Originals",
  "label-lg": {
    "font-size": "14px",
    "text-transform": "capitalize",
  },
  "label-md": {
    "font-size": "12px",
    "text-transform": "capitalize",
  },
  "label-sm": {
    "font-size": "10px",
    "text-transform": "capitalize",
  },
};

export const bodyGeneralSans: BodyConfig = {
  "readable-font-family": "General Sans",
  "font-family": "General Sans",
  "foundry-url": "https://www.fontshare.com/fonts/general-sans",
  source: "free",
  foundry: "FontShare",
  "paragraph-xl": {
    "line-height": "130%",
    "font-weight": 500,
    "text-transform": "uppercase",
  },
  "paragraph-lg": {
    "line-height": "130%",
    "font-weight": 500,
    "text-transform": "uppercase",
  },
  "paragraph-md": {
    "line-height": "130%",
    "font-weight": 500,
    "text-transform": "uppercase",
  },
  "paragraph-sm": {
    "line-height": "130%",
    "font-weight": 500,
    "text-transform": "uppercase",
  },
};

export const bodyClashGrotesk: BodyConfig = {
  "readable-font-family": "Clash Grotesk",
  "font-family": "Clash Grotesk",
  "foundry-url": "https://www.fontshare.com/fonts/clash-grotesk",
  source: "free",
  foundry: "FontShare",
};

export const bodyJafFacit: BodyConfig = {
  "readable-font-family": "JAF Facit",
  "font-family": "jaf-facitweb",
  "foundry-url": "https://fonts.adobe.com/fonts/jaf-facit/?ref=inspotype.com",
  source: "adobe",
  foundry: "Just Another Foundry",
};

export const bodyOpenSans: BodyConfig = {
  "readable-font-family": "Open Sans",
  "font-family": "Open Sans",
  "foundry-url": "https://fonts.google.com/specimen/Open+Sans",
  source: "free",
  foundry: "Google",
};

export const bodyNohemi: BodyConfig = {
  "readable-font-family": "Nohemi",
  "font-family": "Nohemi",
  "foundry-url":
    "https://rajputrajesh-448.gumroad.com/l/NOHEMI9/?ref=inspotype.com",
  source: "free",
  foundry: "Rajput Rajesh",
};

export const bodyFutura: BodyConfig = {
  "readable-font-family": "Futura PT",
  "font-family": "futura-pt",
  "foundry-url": "https://fonts.adobe.com/fonts/futura-pt/?ref=inspotype.com",
  source: "adobe",
  foundry: "Paratype",
};

export const bodyAktivGrotesk: BodyConfig = {
  "readable-font-family": "Aktiv Grotesk",
  "font-family": "aktiv-grotesk",
  "foundry-url":
    "https://fonts.adobe.com/fonts/aktiv-grotesk/?ref=inspotype.com",
  source: "adobe",
  foundry: "Dalton Maag",
};

export const bodyManrope: BodyConfig = {
  "readable-font-family": "Manrope",
  "font-family": "Manrope",
  "foundry-url": "https://fonts.google.com/specimen/Manrope",
  source: "free",
  foundry: "Google",
};

export const bodyRocGrotesk: BodyConfig = {
  "readable-font-family": "Roc Grotesk",
  "font-family": "roc-grotesk",
  "foundry-url": "https://fonts.adobe.com/fonts/roc-grotesk/?ref=inspotype.com",
  source: "adobe",
  foundry: "Kostic Type Foundry",
};

export const bodyInterstate: BodyConfig = {
  "readable-font-family": "Interstate",
  "font-family": "interstate",
  "foundry-url": "https://fonts.adobe.com/fonts/interstate/?ref=inspotype.com",
  source: "adobe",
  foundry: "Frere-Jones Type",
};

export const bodyDMSans: BodyConfig = {
  "readable-font-family": "DM Sans",
  "font-family": "DM Sans",
  "foundry-url": "https://fonts.google.com/specimen/DM+Sans",
  source: "free",
  foundry: "Google",
};

export const bodyPoppins: BodyConfig = {
  "readable-font-family": "Poppins",
  "font-family": "Poppins",
  "foundry-url": "https://fonts.google.com/specimen/Poppins",
  source: "free",
  foundry: "Google",
};

export const bodyPPMori: BodyConfig = {
  "readable-font-family": "PP Mori",
  "font-family": "PPMori",
  "foundry-url": "https://pangrampangram.com/products/mori/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
};

export const bodyPPFragmentSerif: BodyConfig = {
  "readable-font-family": "PP Fragment Serif",
  "font-family": "PPFragmentSerif",
  "foundry-url":
    "https://pangrampangram.com/products/fragment-serif/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
  "paragraph-xl": {
    "line-height": "130%",
    "font-weight": 400,
  },
  "paragraph-lg": {
    "line-height": "130%",
    "font-weight": 400,
  },
  "paragraph-md": {
    "line-height": "130%",
    "font-weight": 400,
  },
  "paragraph-sm": {
    "line-height": "130%",
    "font-weight": 400,
  },
};

export const bodyNimbusSans: BodyConfig = {
  "readable-font-family": "Nimbus Sans Ext",
  "font-family": "nimbus-sans-extended",
  "foundry-url": "https://fonts.adobe.com/fonts/nimbus-sans/?ref=inspotype.com",
  source: "adobe",
  foundry: "URW Type Foundry",
};

export const bodyPPNeueMontreal: BodyConfig = {
  "readable-font-family": "PP Neue Montreal",
  "font-family": "PPNeueMontreal",
  "foundry-url":
    "https://pangrampangram.com/products/neue-montreal/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
};

export const bodyAeonik: BodyConfig = {
  "readable-font-family": "Aeonik",
  "font-family": "Aeonik",
  "foundry-url":
    "https://cotypefoundry.com/our-fonts/aeonik/?ref=inspotype.com",
  source: "custom",
  foundry: "Cotype Foundry",
};

export const bodyMessinaSans: BodyConfig = {
  "readable-font-family": "Messina Sans",
  "font-family": "MessinaSans",
  "foundry-url": "https://www.luzi-type.ch/messina-sans",
  source: "custom",
  foundry: "Luzi Type",
};

export const bodyFaro: BodyConfig = {
  "readable-font-family": "Faro",
  "font-family": "Faro",
  "foundry-url": "https://www.luzi-type.ch/faro",
  source: "custom",
  foundry: "Luzi Type",
};

export const bodyHankenGrotesk: BodyConfig = {
  "readable-font-family": "Hanken Grotesk",
  "font-family": "Hanken Grotesk",
  "foundry-url": "https://fonts.google.com/specimen/Hanken+Grotesk",
  source: "free",
  foundry: "Google",
};

export const bodyPlantin: BodyConfig = {
  "readable-font-family": "Plantin",
  "font-family": "plantin",
  "foundry-url": "https://fonts.adobe.com/fonts/plantin/?ref=inspotype.com",
  source: "adobe",
  foundry: "Monotype",
  "label-lg": {
    "font-weight": 500,
    "text-transform": "full-width",
  },
  "label-md": {
    "font-weight": 500,
    "text-transform": "full-width",
  },
  "label-sm": {
    "font-weight": 500,
    "text-transform": "full-width",
  },
};

export const bodyRobotoSerif: BodyConfig = {
  "readable-font-family": "Roboto Serif",
  "font-family": "Roboto Serif",
  "foundry-url": "https://fonts.google.com/specimen/Roboto+Serif",
  source: "free",
  foundry: "Google",

  "paragraph-xl": {
    "font-weight": 400,
  },
  "paragraph-lg": {
    "font-weight": 400,
  },
  "paragraph-md": {
    "font-weight": 400,
  },
  "paragraph-sm": {
    "font-weight": 400,
  },
  "label-lg": {
    "text-transform": "capitalize",
  },
  "label-md": {
    "text-transform": "capitalize",
  },
  "label-sm": {
    "text-transform": "capitalize",
  },
};

export const bodyVinilaCond: BodyConfig = {
  "readable-font-family": "Vinila Condensed",
  "font-family": "vinila-condensed",
  "foundry-url": "https://fonts.adobe.com/fonts/vinila/?ref=inspotype.com",
  source: "adobe",
  foundry: "Plau",
};

export const bodyStaffGrotesk: BodyConfig = {
  "readable-font-family": "Staff Grotesk",
  "font-family": "Staff",
  "foundry-url":
    "https://www.r-typography.com/fonts/staff-grotesk/?ref=inspotype.com",
  source: "custom",
  foundry: "R-Typography",
};

export const bodyWhyte: BodyConfig = {
  "readable-font-family": "ABC Whyte",
  "font-family": "ABC Whyte",
  "foundry-url": "https://abcdinamo.com/typefaces/whyte/?ref=inspotype.com",
  source: "custom",
  foundry: "ABC Dinamo",
};

export const bodyFavorit: BodyConfig = {
  "readable-font-family": "ABC Favorit",
  "font-family": "ABC Favorit",
  "foundry-url": "https://abcdinamo.com/typefaces/favorit/?ref=inspotype.com",
  source: "custom",
  foundry: "ABC Dinamo",
};

export const bodyAeonikFono: BodyConfig = {
  "readable-font-family": "Aeonik Fono",
  "font-family": "AeonikFono",
  "foundry-url":
    "https://cotypefoundry.com/our-fonts/aeonik-fono/?ref=inspotype.com",
  source: "custom",
  foundry: "Cotype Foundry",
};

export const bodySocial: BodyConfig = {
  "readable-font-family": "ABC Social",
  "font-family": "ABC Social",
  "foundry-url": "https://abcdinamo.com/typefaces/social/?ref=inspotype.com",
  source: "custom",
  foundry: "ABC Dinamo",
  "paragraph-xl": {
    "font-weight": 300,
  },
  "paragraph-lg": {
    "font-weight": 300,
  },
  "paragraph-md": {
    "font-weight": 300,
  },
  "paragraph-sm": {
    "font-weight": 300,
  },
  "label-lg": {
    "font-weight": 300,
  },
  "label-md": {
    "font-weight": 300,
  },
  "label-sm": {
    "font-weight": 300,
  },
};

export const bodyModerat: BodyConfig = {
  "readable-font-family": "Moderat",
  "font-family": "Moderat",
  "foundry-url": "https://tightype.com/typefaces/moderat/?ref=inspotype.com",
  source: "custom",
  foundry: "Tightype",
};

export const bodySneakMono: BodyConfig = {
  "readable-font-family": "Sneak Mono",
  "font-family": "Sneak Mono",
  "foundry-url": "https://tightype.com/typefaces/sneak?ref=inspotype",
  source: "custom",
  foundry: "Tightype",
};

export const bodyBaskerville: BodyConfig = {
  "readable-font-family": "Baskerville URW",
  "font-family": "baskerville-urw",
  "foundry-url":
    "https://fonts.adobe.com/fonts/baskerville-urw/?ref=inspotype.com",
  source: "adobe",
  foundry: "URW Type Foundry",
  "paragraph-xl": {
    "font-size": "20px",
    "line-height": "110%",
    "font-weight": 400,
  },
  "paragraph-lg": {
    "font-size": "18px",
    "line-height": "110%",
    "font-weight": 400,
  },
  "paragraph-md": {
    "font-size": "16px",
    "line-height": "110%",
    "font-weight": 400,
  },
  "paragraph-sm": {
    "font-size": "14px",
    "line-height": "110%",
    "font-weight": 400,
  },
  "label-lg": {
    "font-size": "16px",
    "text-transform": "full-width",
  },
  "label-md": {
    "font-size": "14px",
    "text-transform": "full-width",
  },
  "label-sm": {
    "font-size": "12px",
    "text-transform": "full-width",
  },
};

export const bodySneak: BodyConfig = {
  "readable-font-family": "Sneak",
  "font-family": "Sneak",
  "foundry-url": "https://tightype.com/typefaces/sneak?ref=inspotype",
  source: "custom",
  foundry: "Tightype",
};

export const bodyNeueHaasGrotesk: BodyConfig = {
  "readable-font-family": "Neue Haas Grotesk",
  "font-family": "neue-haas-grotesk-text",
  "foundry-url":
    "https://fonts.adobe.com/fonts/neue-haas-grotesk/?ref=inspotype.com",
  source: "adobe",
  foundry: "Monotype",
};

export const bodyRedHatText: BodyConfig = {
  "readable-font-family": "Red Hat Text",
  "font-family": "Red Hat Text",
  "foundry-url": "https://fonts.google.com/specimen/Red+Hat+Text",
  source: "free",
  foundry: "Google",
};

export const bodyElza: BodyConfig = {
  "readable-font-family": "Elza",
  "font-family": "Elza",
  "foundry-url": "https://fonts.adobe.com/fonts/elza/?ref=inspotype.com",
  source: "adobe",
  foundry: "Blackeletra",
};

export const bodyAcuminProWide: BodyConfig = {
  "readable-font-family": "Acumin Pro Wide",
  "font-family": "acumin-pro-wide",
  "foundry-url": "https://fonts.adobe.com/fonts/acumin-wide/?ref=inspotype.com",
  source: "adobe",
  foundry: "Adobe Originals",
};

export const bodyInstrumentSans: BodyConfig = {
  "readable-font-family": "Instrument Sans",
  "font-family": "Instrument Sans",
  "foundry-url": "https://fonts.google.com/specimen/Instrument+Sans",
  source: "free",
  foundry: "Google",
};

export const bodyCabinetGrotesk: BodyConfig = {
  "readable-font-family": "Cabinet Grotesk",
  "font-family": "Cabinet Grotesk",
  "foundry-url": "https://www.fontshare.com/fonts/cabinet-grotesk",
  source: "free",
  foundry: "FontShare",
  body: {
    "font-weight": 500,
  },
  body2: {
    "font-weight": 500,
  },
  body2Stroke: {
    "font-weight": 500,
  },
  overline: {
    "font-weight": 500,
  },
  overlineStroke: {
    "font-weight": 500,
    "letter-spacing": "1px",
  },
};

export const bodyBarlow: BodyConfig = {
  "readable-font-family": "Barlow",
  "font-family": "Barlow",
  "foundry-url": "https://fonts.google.com/specimen/Barlow",
  source: "free",
  foundry: "Google",
};

export const bodyWorkSans: BodyConfig = {
  "readable-font-family": "Work Sans",
  "font-family": "Work Sans",
  "foundry-url": "https://fonts.google.com/specimen/Work+Sans",
  source: "free",
  foundry: "Google",
};

export const bodyMonumentGrotesk: BodyConfig = {
  "readable-font-family": "ABC Monument Grotesk",
  "font-family": "ABC Monument Grotesk",
  "foundry-url":
    "https://abcdinamo.com/typefaces/monument-grotesk/?ref=inspotype.com",
  source: "custom",
  foundry: "ABC Dinamo",
};

export const bodyRom: BodyConfig = {
  "readable-font-family": "ABC ROM",
  "font-family": "ABC ROM",
  "foundry-url": "https://abcdinamo.com/typefaces/rom/?ref=inspotype.com",
  source: "custom",
  foundry: "ABC Dinamo",
};

export const bodyBallingerMono: BodyConfig = {
  "readable-font-family": "Ballinger Mono",
  "font-family": "ballinger-mono",
  "foundry-url":
    "https://fonts.adobe.com/fonts/ballinger-mono/?ref=inspotype.com",
  source: "adobe",
  foundry: "Signal Type Foundry",
};

export const bodyInconsolata: BodyConfig = {
  "readable-font-family": "Inconsolata",
  "font-family": "Inconsolata",
  "foundry-url": "https://fonts.google.com/specimen/Inconsolata",
  source: "free",
  foundry: "Google",
};

export const bodyRobotoSlab: BodyConfig = {
  "readable-font-family": "Roboto Slab",
  "font-family": "Roboto Slab",
  "foundry-url": "https://fonts.google.com/specimen/Roboto+Slab",
  source: "free",
  foundry: "Google",
};

export const bodyBeVietnamPro: BodyConfig = {
  "readable-font-family": "Be Vietnam Pro",
  "font-family": "Be Vietnam Pro",
  "foundry-url": "https://fonts.google.com/specimen/Be+Vietnam+Pro",
  source: "free",
  foundry: "Google",
};

export const bodyPublicSans: BodyConfig = {
  "readable-font-family": "Public Sans",
  "font-family": "Public Sans",
  "foundry-url": "https://fonts.google.com/specimen/Public+Sans",
  source: "free",
  foundry: "Google",
};

export const bodyHalyard: BodyConfig = {
  "readable-font-family": "Halyard",
  "font-family": "halyard-text",
  "foundry-url": "https://fonts.adobe.com/fonts/halyard/?ref=inspotype.com",
  source: "adobe",
  foundry: "Darden Studio",
};

export const bodyBroadsheet: BodyConfig = {
  "readable-font-family": "Broadsheet",
  "font-family": "broadsheet",
  "foundry-url": "https://fonts.adobe.com/fonts/broadsheet/?ref=inspotype.com",
  source: "adobe",
  foundry: "Three Island Press",
  "paragraph-xl": {
    "font-size": "20px",
    "line-height": "110%",
    "font-weight": 400,
  },
  "paragraph-lg": {
    "font-size": "18px",
    "line-height": "110%",
    "font-weight": 400,
  },
  "paragraph-md": {
    "font-size": "16px",
    "line-height": "110%",
    "font-weight": 400,
  },
  "paragraph-sm": {
    "font-size": "14px",
    "line-height": "110%",
    "font-weight": 400,
  },
  "label-lg": {
    "font-size": "16px",
    "text-transform": "full-width",
  },
  "label-md": {
    "font-size": "14px",
    "text-transform": "full-width",
  },
  "label-sm": {
    "font-size": "12px",
    "text-transform": "full-width",
  },
};

export const bodyPolySansSlim: BodyConfig = {
  "readable-font-family": "PolySans Slim",
  "font-family": "PolySans Slim",
  "foundry-url":
    "https://www.wearegradient.net/typefaces/polysans-standard/?ref=inspotype.com",
  source: "custom",
  foundry: "Gradient",
};

export const bodyRethinkSans: BodyConfig = {
  "readable-font-family": "Rethink Sans",
  "font-family": "Rethink Sans",
  "foundry-url": "https://fonts.google.com/specimen/Rethink+Sans",
  source: "free",
  foundry: "Google",
};

export const bodyMrEavesSans: BodyConfig = {
  "readable-font-family": "Mr Eaves Sans",
  "font-family": "mr-eaves-sans",
  "foundry-url":
    "https://fonts.adobe.com/fonts/mr-eaves-sans/?ref=inspotype.com",
  source: "adobe",
  foundry: "Emigre",
};

export const bodyNeuSans: BodyConfig = {
  "readable-font-family": "Neu Sans",
  "font-family": "NeuSans",
  "foundry-url":
    "https://www.wearegradient.net/typefaces/neusans/?ref=inspotype.com",
  source: "custom",
  foundry: "Gradient",
};

export const bodyRadial: BodyConfig = {
  "readable-font-family": "Radial",
  "font-family": "Radial",
  "foundry-url":
    "https://www.wearegradient.net/typefaces/radial/?ref=inspotype.com",
  source: "custom",
  foundry: "Gradient",
};

export const bodyLinearSans: BodyConfig = {
  "readable-font-family": "Linear Sans",
  "font-family": "LinearSans",
  "foundry-url":
    "https://www.wearegradient.net/typefaces/linear-sans/?ref=inspotype.com",
  source: "custom",
  foundry: "Gradient",
};

export const bodyUncutSans: BodyConfig = {
  "readable-font-family": "Uncut Sans",
  "font-family": "UncutSans",
  "foundry-url": "https://uncut.wtf/sans-serif/uncut-sans/?ref=inspotype.com",
  source: "free",
  foundry: "Uncut.wtf",
};

export const bodyFreight: BodyConfig = {
  "readable-font-family": "Freight",
  "font-family": "freight-display-pro",
  "foundry-url": "https://fonts.adobe.com/fonts/freight/?ref=inspotype.com",
  source: "adobe",
  foundry: "Freight",
};

export const bodyNineElms: BodyConfig = {
  "readable-font-family": "Nine Elms",
  "font-family": "NineElms",
  "foundry-url":
    "https://www.daltonmaag.com/font-library/nine-elms.html?ref=inspotype.com",
  source: "custom",
  foundry: "Dalton Maag",
};

export const bodyHighgate: BodyConfig = {
  "readable-font-family": "Highgate",
  "font-family": "Highgate",
  "foundry-url":
    "https://www.daltonmaag.com/font-library/highgate.html?ref=inspotype.com",
  source: "custom",
  foundry: "Dalton Maag",
};

export const bodyPerenelleSerif: BodyConfig = {
  "readable-font-family": "Perenelle Serif",
  "font-family": "PerenelleSerif",
  "foundry-url":
    "https://www.daltonmaag.com/font-library/perenelle-serif.html?ref=inspotype.com",
  source: "custom",
  foundry: "Dalton Maag",
};

export const bodyGelica: BodyConfig = {
  "readable-font-family": "Gelica",
  "font-family": "gelica",
  "foundry-url": "https://fonts.adobe.com/fonts/gelica/?ref=inspotype.com",
  source: "adobe",
  foundry: "Dave Rowland Type",
};

export const bodyPerenelleSans: BodyConfig = {
  "readable-font-family": "Perenelle Sans",
  "font-family": "PerenelleSans",
  "foundry-url":
    "https://www.daltonmaag.com/font-library/perenelle-sans.html?ref=inspotype.com",
  source: "custom",
  foundry: "Dalton Maag",
  "paragraph-xl": {
    "font-size": "20px",
    "line-height": "110%",
  },
  "paragraph-lg": {
    "font-size": "18px",
    "line-height": "110%",
  },
  "paragraph-md": {
    "font-size": "16px",
    "line-height": "120%",
  },
  "paragraph-sm": {
    "font-size": "14px",
    "line-height": "120%",
  },
  "label-lg": {
    "font-size": "16px",
    "text-transform": "full-width",
  },
  "label-md": {
    "font-size": "14px",
    "text-transform": "full-width",
  },
  "label-sm": {
    "font-size": "12px",
    "text-transform": "full-width",
  },
};

export const bodyOrbikular: BodyConfig = {
  "readable-font-family": "Orbikular",
  "font-family": "Orbikular",
  "foundry-url":
    "https://cotypefoundry.com/our-fonts/orbikular/?ref=inspotype.com",
  source: "custom",
  foundry: "Cotype Foundry",
};

export const bodySatori: BodyConfig = {
  "readable-font-family": "Satori",
  "font-family": "Satori",
  "foundry-url": "https://typeoffeeling.com/products/satori/?ref=inspotype.com",
  source: "custom",
  foundry: "Type of Feeling",
};

export const bodyConforto: BodyConfig = {
  "readable-font-family": "Conforto",
  "font-family": "Conforto",
  "foundry-url":
    "https://typeoffeeling.com/products/conforto/?ref=inspotype.com",
  source: "custom",
  foundry: "Type of Feeling",
};

export const bodyALTSystema: BodyConfig = {
  "readable-font-family": "ALT Systema",
  "font-family": "ALTSystema",
  "foundry-url": "https://alt-tf.com/products/alt-systema/?ref=inspotype.com",
  source: "custom",
  foundry: "ALT.tf",
};

export const bodyALTRiviera: BodyConfig = {
  "readable-font-family": "ALT Riviera",
  "font-family": "ALTRiviera",
  "foundry-url": "https://alt-tf.com/products/alt-riviera/?ref=inspotype.com",
  source: "custom",
  foundry: "ALT.tf",
};

export const bodyBastardoGrotesk: BodyConfig = {
  "readable-font-family": "Bastardo Grotesk",
  "font-family": "BastardoGrotesk",
  "foundry-url":
    "https://www.giuliaboggio.xyz/fonts/bastardo-grotesk/?ref=inspotype.com",
  source: "custom",
  foundry: "ALT.tf",
};

export const bodyUfficioSans: BodyConfig = {
  "readable-font-family": "Ufficio Sans",
  "font-family": "UfficioSans",
  "foundry-url":
    "https://www.giuliaboggio.xyz/fonts/ufficio-sans/?ref=inspotype.com",
  source: "custom",
  foundry: "ALT.tf",
};

export const bodyFabioXM: BodyConfig = {
  "readable-font-family": "Fabio XM",
  "font-family": "FabioXM",
  "foundry-url":
    "https://www.giuliaboggio.xyz/fonts/fabio-xm/?ref=inspotype.com",
  source: "custom",
  foundry: "ALT.tf",
};

export const bodyBookmania: BodyConfig = {
  "readable-font-family": "Bookmania",
  "font-family": "bookmania",
  "foundry-url": "https://fonts.adobe.com/fonts/bookmania/?ref=inspotype.com",
  source: "adobe",
  foundry: "Mark Simonson Studio",
};

export const bodyOverusedGrotesk: BodyConfig = {
  "readable-font-family": "Overused Grotesk",
  "font-family": "OverusedGrotesk",
  "foundry-url": "https://uncut.wtf/sans-serif/overused-grotesk/",
  source: "free",
  foundry: "Uncut.wtf",
};

export const bodySpaceGrotesk: BodyConfig = {
  "readable-font-family": "Space Grotesk",
  "font-family": "Space Grotesk",
  "foundry-url": "https://fonts.google.com/specimen/Space+Grotesk",
  source: "free",
  foundry: "Google",
};

export const bodyGillSansNova: BodyConfig = {
  "readable-font-family": "Gill Sans Nova",
  "font-family": "gill-sans-nova",
  "foundry-url":
    "https://fonts.adobe.com/fonts/gill-sans-nova/?ref=inspotype.com",
  source: "adobe",
  foundry: "Monotype",
};

export const bodyPlayfairDisplay: BodyConfig = {
  "readable-font-family": "Playfair Display",
  "font-family": "Playfair Display",
  "foundry-url": "https://fonts.google.com/specimen/Playfair+Display",
  source: "free",
  foundry: "Google",
};

export const bodyPPFragmentSans: BodyConfig = {
  "readable-font-family": "PP Fragment Sans",
  "font-family": "PPFragmentSans",
  "foundry-url":
    "https://pangrampangram.com/products/fragment-sans/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
};

export const bodyGeist: BodyConfig = {
  "readable-font-family": "Geist",
  "font-family": "Geist",
  "foundry-url": "https://fonts.google.com/specimen/Geist",
  source: "free",
  foundry: "Google Fonts",
};

export const bodyMontserrat: BodyConfig = {
  "readable-font-family": "Montserrat ",
  "font-family": "Montserrat",
  "foundry-url": "https://fonts.google.com/specimen/Montserrat",
  source: "free",
  foundry: "Google Fonts",
};

export const bodyBwNistaGrot: BodyConfig = {
  "readable-font-family": "Bw Nista Grot",
  "font-family": "BwNistaGrot",
  "foundry-url":
    "https://brandingwithtype.com/typefaces/bw-nista?ref=inspotype.com",
  source: "custom",
  foundry: "Branding with Type",
};

export const bodyBwModelica: BodyConfig = {
  "readable-font-family": "Bw Modelica",
  "font-family": "BwModelica",
  "foundry-url":
    "https://brandingwithtype.com/typefaces/bw-modelica?ref=inspotype.com",
  source: "custom",
  foundry: "Branding with Type",
};

export const bodyBwSeidoRaw: BodyConfig = {
  "readable-font-family": "Bw Seido Raw",
  "font-family": "BwSeidoRaw",
  "foundry-url":
    "https://brandingwithtype.com/typefaces/bw-seido-raw?ref=inspotype.com",
  source: "custom",
  foundry: "Branding with Type",
};

export const bodyWorkhorseGeometric: BodyConfig = {
  "readable-font-family": "Workhorse Geometric",
  "font-family": "WorkhorseGeometric",
  "foundry-url":
    "https://workhorse.studio/pages/workhorse-geometric/?ref=inspotype.com",
  source: "custom",
  foundry: "Workhorse Type Foundry",
};

export const bodyWorkhorseGeoMono: BodyConfig = {
  "readable-font-family": "Workhorse Geo Mono",
  "font-family": "WorkhorseGeoMono",
  "foundry-url":
    "https://workhorse.studio/pages/workhorse-geo-mono/?ref=inspotype.com",
  source: "custom",
  foundry: "Workhorse Type Foundry",
};

export const bodyITCFranklinGothic: BodyConfig = {
  "readable-font-family": "ITC Franklin Gothic",
  "font-family": "franklin-gothic-compressed",
  "foundry-url":
    "https://fonts.adobe.com/fonts/itc-franklin-gothic/?ref=inspotype.com",
  source: "adobe",
  foundry: "Monotype",
};

export const bodyBlanchard: BodyConfig = {
  "readable-font-family": "Blanchard",
  "font-family": "Blanchard",
  "foundry-url":
    "https://commercialtype.com/catalog/blanchard/?ref=inspotype.com",
  source: "custom",
  foundry: "Commercial Type",
};

export const bodyAtlasGrotesk: BodyConfig = {
  "readable-font-family": "Atlas Grotesk",
  "font-family": "AtlasGrotesk",
  "foundry-url": "https://commercialtype.com/catalog/atlas/?ref=inspotype.com",
  source: "custom",
  foundry: "Commercial Type",
};

export const bodyMoulin: BodyConfig = {
  "readable-font-family": "Moulin",
  "font-family": "Moulin",
  "foundry-url": "https://commercialtype.com/catalog/moulin/?ref=inspotype.com",
  source: "custom",
  foundry: "Commercial Type",
};

export const bodyDelegate: BodyConfig = {
  "readable-font-family": "Delegate",
  "font-family": "Delegate",
  "foundry-url":
    "https://commercialtype.com/catalog/delegate/?ref=inspotype.com",
  source: "custom",
  foundry: "Commercial Type",
};

export const bodyCanela: BodyConfig = {
  "readable-font-family": "Canela",
  "font-family": "Canela",
  "foundry-url": "https://commercialtype.com/catalog/canela/?ref=inspotype.com",
  source: "custom",
  foundry: "Commercial Type",
};
