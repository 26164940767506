import { Box, Text, Title } from "@mantine/core";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import {
  customizeTextVariantIfNeededForCardPreview,
  type CardPreviewCustomizationProps,
  type InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import { InspoCardText, InspoCardTitle } from "../ui/InspoCardContent";

type SavingsContentProps = {
  title: string;
  subtitle: string;
  subtitle2: string;
};

export const savingsContent = {
  finance: {
    title: "USD 1,470",
    subtitle: "Saved today",
    subtitle2: "Used from budget",
  },
} as const satisfies Record<string, SavingsContentProps>;

export default function Savings({
  colorScheme,
  content,
  titleFontFamily,
  bodyFontFamily,
  ...props
}: InspoTypeCardProps<SavingsContentProps> &
  BentoCardChildrenProps &
  CardPreviewCustomizationProps) {
  if (!content) return null;

  const data = { progressBars: 20, completedBars: 12, percentage: "64%" };

  return (
    <Vertical
      fullW
      fullH
      center
      bg={colorScheme.background}
      px={"md"}
      py={"md"}
      {...props}
    >
      <Vertical spaceBetween fullH fullW>
        <Vertical gap={"xxs"}>
          <InspoCardText
            c={colorScheme.textdark}
            variant={customizeTextVariantIfNeededForCardPreview(
              "card-paragraph-md",
              bodyFontFamily,
            )}
            ff={bodyFontFamily}
            textId="title"
            {...props}
          >
            {content.title}
          </InspoCardText>

          <InspoCardText
            c={colorScheme.textdark}
            variant={customizeTextVariantIfNeededForCardPreview(
              "card-label-md",
              bodyFontFamily,
            )}
            ff={bodyFontFamily}
            textId="subtitle"
            {...props}
          >
            {content.subtitle}
          </InspoCardText>
        </Vertical>

        <Horizontal fullW noWrap gap={0} justify="space-between">
          {[...Array(data.progressBars)].map((_, index) => (
            <Box
              key={index}
              h={60}
              w={10}
              bg={
                index > data.completedBars
                  ? colorScheme.tone
                  : colorScheme.ctadark
              }
              style={{ borderRadius: "var(--mantine-radius-xs)" }}
            />
          ))}
        </Horizontal>

        <Vertical gap={"xxs"}>
          <InspoCardTitle
            variant={customizeTextVariantIfNeededForCardPreview(
              "card-display-md",
              titleFontFamily,
            )}
            c={colorScheme.textdark}
            ff={titleFontFamily}
            textId="title"
            {...props}
          >
            {data.percentage}
          </InspoCardTitle>
          <InspoCardText
            c={colorScheme.textdark}
            variant={customizeTextVariantIfNeededForCardPreview(
              "card-label-md",
              bodyFontFamily,
            )}
            ff={bodyFontFamily}
            textId="subtitle2"
            {...props}
          >
            {content.subtitle2}
          </InspoCardText>
        </Vertical>
      </Vertical>
    </Vertical>
  );
}
