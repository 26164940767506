interface ProAccessCode {
  code: string;
  expiresAt: string; // ISO date string
}

// List of valid pro access codes with expiration dates
// to be used like this:
// https://inspotype.com/?pro=example-code
const PRO_ACCESS_CODES: ProAccessCode[] = [
  // {
  //   code: "example-code",
  //   expiresAt: "2025-12-31T23:59:59Z"
  // },
];

export function isValidProAccessCode(code: string): boolean {
  const accessCode = PRO_ACCESS_CODES.find(ac => ac.code === code.toLowerCase());
  if (!accessCode) return false;
  
  const now = new Date();
  const expirationDate = new Date(accessCode.expiresAt);
  
  return now < expirationDate;
}

export function setTemporaryProAccess() {
  sessionStorage.setItem('temporary_pro_access', 'true');
}

export function hasTemporaryProAccess(): boolean {
  return sessionStorage.getItem('temporary_pro_access') === 'true';
}