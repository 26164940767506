import { Image, Text } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import { InspoCardText } from "../ui/InspoCardContent";

type ImageLeftContentProps = {
  label1: string;
  label2: string;
  label3: string;
  label4: string;
  imgs: string[];
  alt: string;
  align: "center" | "bottomLeft";
};

export const imageLeftContent = {
  fashion: {
    label1: "Conscious",
    label2: "Sustainable",
    label3: "Handmade",
    label4: "Streetware",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },

  beauty: {
    label1: "Clean Beauty",
    label2: "Cruelty-Free",
    label3: "Organic",
    label4: "Natural",
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },

  food: {
    label1: "Farm-to-Table",
    label2: "Vegan",
    label3: "Artisanal",
    label4: "Local",
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },

  editorial: {
    label1: "Independent",
    label2: "Digital",
    label3: "Print",
    label4: "Narrative-Driven",
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },

  finance: {
    label1: "Investment",
    label2: "Blockchain",
    label3: "Sustainable Finance",
    label4: "Decentralized",
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },

  sport: {
    label1: "Athleisure",
    label2: "Performance",
    label3: "Eco-friendly Gear",
    label4: "Endurance",
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },
} as const satisfies Record<string, ImageLeftContentProps>;

export default function ImageLeft({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<ImageLeftContentProps> & BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Horizontal
      fullW
      fullH
      bg={colorScheme.background}
      gap={0}
      noWrap
      {...props}
    >
      <Image
        src={uploadedImagePath ?? content.imgs[imgIndex]}
        alt={content.alt}
        radius={"xxs"}
        fit="cover"
        px={"md"}
        py={"md"}
        h={"100%"}
        w={"70%"}
      />
      <Vertical gap={"xs"} px={"lg"} py={"lg"} maw={"50%"} fullH spaceBetween>
        <InspoCardText
          variant="card-paragraph-sm"
          c={colorScheme.textdark}
          ta={"left"}
          textId="label1"
          {...props}
        >
          {content.label1}
        </InspoCardText>
        <InspoCardText
          variant="card-paragraph-sm"
          c={colorScheme.textdark}
          ta={"left"}
          textId="label2"
          {...props}
        >
          {content.label2}
        </InspoCardText>
        <InspoCardText
          variant="card-paragraph-sm"
          c={colorScheme.textdark}
          ta={"left"}
          textId="label3"
          {...props}
        >
          {content.label3}
        </InspoCardText>
        <InspoCardText
          variant="card-paragraph-sm"
          c={colorScheme.textdark}
          ta={"left"}
          textId="label4"
          {...props}
        >
          {content.label4}
        </InspoCardText>
      </Vertical>
    </Horizontal>
  );
}
