import React from "react";
import {
  customizeTextVariantIfNeededForCardPreview,
  type BentoCardChildrenProps,
  type CardPreviewCustomizationProps,
  type InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import { Avatar, Text, Title } from "@mantine/core";
import "./../../../../styles/CustomComponents.module.scss";
import { InspoCardText } from "../ui/InspoCardContent";
import { InspoCardTitle } from "../ui/InspoCardContent";

type AuthorsContentProps = {
  items: {
    title: string;
    subtitle: string;
  }[];
};

export const authorsContent = {
  editorial: {
    items: [
      {
        title: "Shana Solarte",
        subtitle: "Writer",
      },
      {
        title: "Laura Burns",
        subtitle: "Technical Consultant",
      },
      {
        title: "Lisa Beck",
        subtitle: "Photography",
      },
    ],
  },

  sport: {
    items: [
      {
        title: "Badminton",
        subtitle: "42 items",
      },
      {
        title: "Baseball",
        subtitle: "82 items",
      },
      {
        title: "Basketball",
        subtitle: "91 items",
      },
    ],
  },
} as const satisfies Record<string, AuthorsContentProps>;

export default function Authors({
  colorScheme,
  content,
  titleFontFamily,
  bodyFontFamily,
  ...props
}: InspoTypeCardProps<AuthorsContentProps> &
  BentoCardChildrenProps &
  CardPreviewCustomizationProps) {
  if (!content) return null;

  return (
    <Vertical
      fullW
      fullH
      bg={colorScheme.background}
      px={"md"}
      center
      {...props}
    >
      <Vertical mx={"auto"} gap={"sm"}>
        {content.items.map((el, i) => (
          <Horizontal key={i} spaceBetween noWrap>
            <Vertical gap={"xxs"}>
              <InspoCardTitle
                c={colorScheme.textdark}
                variant={customizeTextVariantIfNeededForCardPreview(
                  "card-headline-xs",
                  titleFontFamily,
                )}
                ff={titleFontFamily}
                textId={`item-${i}-title`}
                {...props}
              >
                {el.title}
              </InspoCardTitle>
              <InspoCardText
                variant={customizeTextVariantIfNeededForCardPreview(
                  "card-paragraph-md",
                  bodyFontFamily,
                )}
                c={colorScheme.textdark}
                ff={bodyFontFamily}
                textId={`item-${i}-subtitle`}
                {...props}
              >
                {el.subtitle}
              </InspoCardText>
            </Vertical>
          </Horizontal>
        ))}
      </Vertical>
    </Vertical>
  );
}
