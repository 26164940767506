import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import { fontPairs as allFontPairs } from "src/utils/typography/configs/fontPairs";
import { useHotkeys, useLocalStorage } from "@mantine/hooks";
import { openModalLoginIfNeeded } from "../components/ModalLogin";
import { useUser } from "./UserContext";
import { InspoEvent, LifetimeModalTrigger, track } from "../utils";
import { setTitleVariables } from "src/utils/typography/configs/titleFontConfig";
import { setBodyVariables } from "src/utils/typography/configs/bodyFontConfig";
import { openModalLifetimeIfNeeded } from "../components/ModalLifetime";

interface FontsContextType {
  localFonts: any[];
  fetchLocalFonts: () => void;
  fontPairs: any[];
  fontPairIndex: number;
  setFontPairIndex: (index: number) => void;
  currentFontPair: any;
  handleFontPairChange: (change: "back" | "next" | "random") => void;
  title: FontContextReturn;
  body: FontContextReturn;
}

const FontsContext = createContext<FontsContextType | undefined>(undefined);

interface FontContextReturn {
  font: {
    "readable-font-family": string;
    "font-family": string;
    "foundry-url": string;
    source?: string;
  };
  toggleLockFont: () => void;
  isLockedAtFont: string | undefined;
  isLocal: boolean | undefined;
  setIsLocal: (value: boolean) => void;
  setFontAt: (params: {
    readableFontFamily: string;
    isLocal?: boolean;
    shouldLock?: boolean;
    shouldSaveHistory?: boolean;
  }) => void;
}

export function FontsProvider({ children }: { children: React.ReactNode }) {
  const { user, isPro } = useUser();

  const isMobile = useIsMobile();

  const fontPairs = useMemo(() => {
    const excludedFontPairsSlugs = [
      "ppcirka_nimbussans",
      "ginto_aeonikfono",
      "rubik_opensans",
      "plaid_moderat",
      "syncopate_robotoserif",
      "termina_epilogue",
      "clashdisplay_clashgrotesk",
      "margobeuys_dmsans",
      "clash_archivo",
      "oswald_tirotamil",
    ];

    if (isMobile) {
      return allFontPairs.filter(
        (pair) => !excludedFontPairsSlugs.includes(pair.slug),
      );
    }

    return allFontPairs;
  }, [isMobile]);

  const [fontPairIndex, setFontPairIndex] = useLocalStorage({
    key: "font_pair_index",
    defaultValue: 0,
    getInitialValueInEffect: false,
  });

  const currentFontPair = fontPairs[fontPairIndex];
  const [localFonts, setLocalFonts] = useState<any>([]);

  const titleFont = useFontForFontContext(
    "title",
    fontPairs,
    localFonts,
    currentFontPair,
  );
  const bodyFont = useFontForFontContext(
    "body",
    fontPairs,
    localFonts,
    currentFontPair,
  );

  const fetchLocalFonts = useCallback(async () => {
    if (!("queryLocalFonts" in window)) return;

    // @ts-ignore
    const localFonts = await window
      // @ts-ignore
      .queryLocalFonts()
      .then((fonts: any) => {
        return fonts.reduce((acc: any, curr: any) => {
          if (acc.find((f: any) => f.family === curr.family)) return acc;
          acc.push(curr);
          return acc;
        }, [] as any[]);
      })
      .catch(async (err: any) => {
        console.error(err);
        return [];
      });

    setLocalFonts(localFonts);
  }, []);

  useEffect(() => {
    fetchLocalFonts();
  }, [fetchLocalFonts]);

  function handleFontPairChange(change: "back" | "next" | "random") {
    track({
      event: "change_font",
      properties: { action: change },
    });

    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "font.change",
      })
    ) {
      return;
    }

    let newIndex;
    switch (change) {
      case "back":
        newIndex =
          fontPairIndex === 0 ? fontPairs.length - 1 : fontPairIndex - 1;
        break;
      case "next":
        newIndex =
          fontPairIndex === fontPairs.length - 1 ? 0 : fontPairIndex + 1;
        break;
      case "random":
        newIndex = Math.floor(Math.random() * fontPairs.length);
        break;
    }

    setFontPairIndex(newIndex);
    document.dispatchEvent(new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT));
  }

  useHotkeys([
    [
      "ArrowLeft",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.arrowleft",
          })
        ) {
          return;
        }

        if (
          openModalLifetimeIfNeeded({
            user,
            isPro,
            source: LifetimeModalTrigger.FONT_PICKER,
          })
        ) {
          return;
        }

        track({
          event: "change_font",
          properties: { source: "keyboard", action: "back" },
        });
        handleFontPairChange("back");
      },
    ],
    [
      "ArrowRight",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.arrowright",
          })
        ) {
          return;
        }

        if (
          openModalLifetimeIfNeeded({
            user,
            isPro,
            source: LifetimeModalTrigger.FONT_PICKER,
          })
        ) {
          return;
        }

        track({
          event: "change_font",
          properties: { source: "keyboard", action: "next" },
        });
        handleFontPairChange("next");
      },
    ],
  ]);

  return (
    <FontsContext.Provider
      value={{
        localFonts,
        fetchLocalFonts,
        fontPairs,
        fontPairIndex,
        setFontPairIndex,
        currentFontPair,
        handleFontPairChange,
        title: titleFont,
        body: bodyFont,
      }}
    >
      {children}
    </FontsContext.Provider>
  );
}

export function useFonts(): FontsContextType {
  const context = useContext(FontsContext);
  if (context === undefined) {
    throw new Error("useFonts must be used within a FontsProvider");
  }
  return context;
}

export function useFont(type: "heading" | "body"): FontContextReturn {
  const { title, body } = useFonts();
  return type === "heading" ? title : body;
}

function useFontForFontContext(
  type: "title" | "body",
  fontPairs: any[],
  localFonts: any[],
  currentFontPair: any,
): FontContextReturn {
  const [font, setFont] = useState<any | undefined>(currentFontPair[type]);

  const [isLockedAtFont, setIsLockedAtFont] = useState<string | undefined>(
    undefined,
  );
  const [isLocal, setIsLocal] = useState<boolean | undefined>(undefined);

  useEffect(
    function setFontOnChangeOfCurrentFontPair() {
      if (isLockedAtFont === undefined) {
        const font = currentFontPair[type];
        setFont(font);
      }
    },
    [currentFontPair[type]],
  );

  useEffect(() => {
    if (isLockedAtFont === undefined) {
      setFontVariables(type, font);
    }
  }, [font]);

  // useEffect(() => {
  //   async function innerSetFontVariables() {
  //     if (isLockedAtFont !== undefined) {
  //       if (isLocal) {
  //         const localFont = await window
  //           // @ts-ignore
  //           .queryLocalFonts()
  //           .then((fonts: any) =>
  //             fonts.find((f: any) => f.family === isLockedAtFont),
  //           );
  //         if (localFont) {
  //           setFontVariables(type, {
  //             "readable-font-family": localFont.family,
  //             "font-family": localFont.family,
  //           });
  //         }
  //       } else {
  //         for (const pair of fontPairs) {
  //           if (pair[type]["readable-font-family"] === isLockedAtFont) {
  //             setFontVariables(type, pair[type]);
  //             break;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   innerSetFontVariables();
  // }, [isLocal, isLockedAtFont]);

  // useEffect(() => {
  //   if (isLockedAtFont !== undefined && !isLocal) {
  //     for (const pair of fontPairs) {
  //       if (pair[type]["readable-font-family"] === isLockedAtFont) {
  //         setFont(pair[type]);
  //         return;
  //       }
  //     }
  //   }
  //   if (isLockedAtFont !== undefined && isLocal) {
  //     const localFont = localFonts.find(
  //       (f: any) => f.family === isLockedAtFont,
  //     );
  //     if (!localFont) return;
  //     const font = {
  //       "readable-font-family": localFont.family,
  //       "font-family": localFont.family,
  //       "foundry-url": "",
  //       source: "local",
  //     };
  //     setFont(font);
  //     return;
  //   }
  //   if (isLockedAtFont !== undefined) {
  //     setFont(isLockedAtFont);
  //     return;
  //   }
  // }, [localFonts, isLocal, isLockedAtFont]);

  function setFontAt({
    readableFontFamily,
    isLocal = false,
    shouldLock = false,
    shouldSaveHistory = false,
  }: {
    readableFontFamily: string;
    isLocal?: boolean;
    shouldLock?: boolean;
    shouldSaveHistory?: boolean;
  }) {
    let font;
    if (isLocal) {
      const localFont = localFonts.find(
        (f: any) => f.family === readableFontFamily,
      );
      if (!localFont) return;

      font = {
        "readable-font-family": localFont.family,
        "font-family": localFont.family,
        "foundry-url": "",
        source: "local",
      };
    } else {
      const fontPair = fontPairs.find(
        (f) => f[type]["readable-font-family"] === readableFontFamily,
      );
      if (!fontPair) return;
      font = fontPair[type];
    }

    setFont(font);
    shouldLock
      ? setIsLockedAtFont(readableFontFamily)
      : setIsLockedAtFont(undefined);
    setIsLocal(isLocal);

    if (shouldSaveHistory) {
      document.dispatchEvent(
        new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT),
      );
    }
  }

  function setFontVariables(type: "title" | "body", font: any) {
    switch (type) {
      case "title":
        setTitleVariables(font);
        break;
      case "body":
        setBodyVariables(font);
        break;
    }
  }

  function toggleLockFont() {
    if (isLockedAtFont === undefined) {
      setIsLockedAtFont(font["readable-font-family"]);
    } else {
      setIsLockedAtFont(undefined);
    }
    document.dispatchEvent(new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT));
  }

  return {
    font,
    toggleLockFont,
    isLockedAtFont,
    isLocal,
    setIsLocal,
    setFontAt,
  };
}
