import { Text, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Vertical } from "../mantine-layout-components/Vertical";
import { InspoCardText, InspoCardTitle } from "../ui/InspoCardContent";

type HeadingWithBgColorContentProps = {
  title: string;
  subtitle: string;
  labelBelowTitle: string;
};

export const headingWithBgColorContent = {
  food: {
    title: "Mediterraneo",
    subtitle: "A classic combination of chicken and mushrooms.",
    labelBelowTitle: "$18",
  },
  beauty: {
    title: "Vitamin C Serum",
    subtitle:
      "Vegan ingredients to hydrate, brighten and protect the delicate & sensitive skin.",
    labelBelowTitle: "$9",
  },
  finance: {
    title: "Premium Account",
    subtitle:
      "All-in-one banking solution with advanced analytics and zero fees.",
    labelBelowTitle: "$15/month",
  },
  sport: {
    title: "Elite Training",
    subtitle:
      "Personalized coaching program with real-time performance tracking.",
    labelBelowTitle: "$49/mo",
  },
  editorial: {
    title: "Exclusive Access",
    subtitle: "Unlimited access to premium content and exclusive stories.",
    labelBelowTitle: "$9/mo",
  },
  fashion: {
    title: "Premium Collection",
    subtitle:
      "Sustainable materials meet contemporary design in our signature line.",
    labelBelowTitle: "$129",
  },
} as const satisfies Record<string, HeadingWithBgColorContentProps>;

export default function HeadingWithBgColor({
  colorScheme,
  content,
  ...props
}: InspoTypeCardProps<HeadingWithBgColorContentProps> &
  BentoCardChildrenProps) {
  if (!content) return null;

  return (
    <Vertical
      fullW
      fullH
      center
      px="md"
      gap={"xs"}
      bg={colorScheme.background}
      {...props}
    >
      <InspoCardTitle
        variant="card-headline-xs"
        c={colorScheme.textdark}
        ta={"center"}
        textId="title"
        {...props}
      >
        {content.title}
      </InspoCardTitle>

      <InspoCardText
        variant="card-paragraph-sm"
        c={colorScheme.textdark}
        ta={"center"}
        textId="subtitle"
        {...props}
      >
        {content.subtitle}
      </InspoCardText>

      <InspoCardText
        variant="card-label-lg"
        c={colorScheme.textdark}
        ta={"center"}
        textId="labelBelowTitle"
        {...props}
      >
        {content.labelBelowTitle}
      </InspoCardText>
    </Vertical>
  );
}
